import { SecurityScopeNames } from '@mmw/services-auth-api-authentication/types';
import { F, U } from 'ts-toolbelt';

import {
  AuthenticationResponseWithUser,
  CLEAR_ERROR,
  ClearErrorAction,
  LOGIN_BY_PASSWORD_ERROR,
  LOGIN_BY_PASSWORD_START,
  LOGIN_BY_PASSWORD_SUCCESS,
  LoginByPasswordErrorAction,
  LoginByPasswordStartAction,
  LoginByPasswordSuccessAction,
  SET_RECAPTCHA_RESPONSE,
  SET_USERNAME,
  SetRecaptchaResponseAction,
  SetUsernameAction,
} from './types';

export const setUsernameAction = (username: string): SetUsernameAction => ({
  type: SET_USERNAME,
  payload: {
    username,
  },
});

export const clearErrorAction = (): ClearErrorAction => ({
  type: CLEAR_ERROR,
  payload: {},
});

export const setRecaptchaResponseAction = (
  recaptchaResponse: string,
): SetRecaptchaResponseAction => ({
  type: SET_RECAPTCHA_RESPONSE,
  payload: {
    recaptchaResponse,
  },
});

export const loginByPasswordStartAction = (
  password: string,
  onLoginSuccess?: F.Function<[AuthenticationResponseWithUser]>,
): LoginByPasswordStartAction => ({
  type: LOGIN_BY_PASSWORD_START,
  payload: { password, onLoginSuccess },
});

export const loginByUserAndPassStartAction = (
  username: string,
  password: string,
  recaptchaResponse?: U.Nullable<string>,
  onLoginSuccess?: F.Function<[AuthenticationResponseWithUser]>,
  scopeNames?: SecurityScopeNames[],
  disableAuthRedirection?: boolean,
): LoginByPasswordStartAction => ({
  type: LOGIN_BY_PASSWORD_START,
  payload: {
    username,
    password,
    onLoginSuccess,
    recaptchaType: 'V3',
    recaptchaResponse,
    scopeNames,
    disableAuthRedirection,
  },
});

export const loginAppByPassStartAction = (
  username: string,
  password: string,
  applicationId?: U.Nullable<string>,
  recaptchaResponse?: U.Nullable<string>,
  onLoginSuccess?: F.Function<[AuthenticationResponseWithUser]>,
  applicationBaseUrl?: U.Nullable<string>,
  applicationPath?: U.Nullable<string>,
  applicationContextPath?: U.Nullable<string>,
): LoginByPasswordStartAction => ({
  type: LOGIN_BY_PASSWORD_START,
  payload: {
    username,
    password,
    applicationId,
    recaptchaType: 'V3',
    recaptchaResponse,
    onLoginSuccess,
    applicationBaseUrl,
    applicationPath,
    applicationContextPath,
  },
});

export const loginByPasswordWithRecaptchaStartAction = (
  password: string,
  recaptchaResponse: string,
  recaptchaType: 'V2' | 'V3',
  onLoginSuccess?: F.Function<[AuthenticationResponseWithUser]>,
): LoginByPasswordStartAction => ({
  type: LOGIN_BY_PASSWORD_START,
  payload: {
    password,
    onLoginSuccess,
    recaptchaResponse,
    recaptchaType,
  },
});

export const loginByPasswordSuccessAction = (
  response: AuthenticationResponseWithUser,
): LoginByPasswordSuccessAction => ({
  type: LOGIN_BY_PASSWORD_SUCCESS,
  payload: {
    ...response,
  },
});

export const loginByPasswordErrorAction = (
  error: Error,
): LoginByPasswordErrorAction => ({
  type: LOGIN_BY_PASSWORD_ERROR,
  payload: {
    error,
  },
});
