import defaultAuthApi, { ApiResponse } from '@mmw/api-auth';
import { StoreInterface } from '@mmw/common-aync-storage';
import { CacheInterface } from '@mmw/common-cache';
import { ClientFingerprint } from '@mmw/common-client-fingerprint';
import { AUTHENTICATION_EMAIL_APPLICATION_BASE_URL } from '@mmw/constants-application-paths';
import { LanguageCode } from '@mmw/constants-languages';
import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import contextualConfig from '@mmw/contextual-config';
import { isDevelopment } from '@mmw/environment';
import { singleton as tokenParser } from '@mmw/services-auth-api-token-parser';
import { TokenParseResult } from '@mmw/services-auth-api-token-parser/types';
import { AuthenticationRequiredError } from '@mmw/services-core-projects/errors';
import { IsAuthenticationService } from '@mmw/services-interfaces-is-authentication';
import { isEmail as emailRegexTest } from '@mmw/utils-email';
import autoBind from 'auto-bind';
import {
  chain,
  filter,
  find,
  includes,
  isEmpty,
  isObject,
  keys,
  trim,
} from 'lodash';
import qs from 'qs';
import { U } from 'ts-toolbelt';
import { Container } from 'typedi';

import {
  AuthenticateBySSOTokenPath,
  AuthenticateByTanPath,
  AuthenticatePath,
  ChangePasswordPath,
  GenerateSSOTokenByRefreshTokenPath,
  GenerateSSOTokenBySystemTokenPath,
  GetUseridByEmailPath,
  GoogleTokenAuthPath,
  LogoutPath,
  RecoverPasswordPath,
  RecoverUsernamePath,
  RefreshAuthenticationPath,
  RequestEmailVerificationPath,
  RequestTanPath,
  RequestUnblockPath,
  RequestUserVerificationBySmsPath,
  RETAIL_CLIENT_REFERER_HEADERS,
  SendAuthenticationEmailPath,
  ValidateChangePasswordUuidPath,
  ValidateSystemTokenPath,
  VerifyEmailPath,
  VerifyPhonePath,
} from './apiPaths';
import {
  BadCredentialsError,
  CaptchaBlockedError,
  DuplicatedEmailError,
  EmailBlockedError,
  InvalidApplicationError,
  MissingAuthorityError,
  MissingSalesOrgBusinessRelationError,
  TanRequestNotSuccessfullError,
  TanReSendRequestNotSuccessfullError,
  UnknownError,
  UserNotFoundError,
  UserScopeNameNotAllowedError,
} from './errors';
import {
  ACCESS_TOKEN_KEY,
  DEFAULT_ACCESS_TOKEN_HEADER_NAME,
  LOGIN_CALLBACK_URL_STORAGE_KEY,
  LOGOUT_CALLBACK_URL_STORAGE_KEY,
  REFRESH_TOKEN_KEY,
} from './keys';
import logger from './log';
import {
  AuthenticationByTanRequest,
  AuthenticationHeaders,
  AuthenticationRequest,
  AuthenticationResponse,
  AuthenticationServiceOptions,
  BaseEmailConfiguration,
  EmailConfiguration,
  emptyUserLoginConfiguration,
  GenerateSSOTokenResult,
  GoogleAuthenticationRequest,
  IsAuthenticatedOptions,
  OperationResult,
  RecoverPasswordBaseParams,
  RefreshAuthenticationResponse,
  RequestEmailVerificationRequest,
  RequestPhonelVerificationRequest,
  RequestTanRequest,
  RoleStatus,
  SecurityScopeNames,
  SendAuthenticationEmailRequest,
  SmsConfiguration,
  TwoFactorAuthResult,
  UnblockRequest,
  UnblockResponse,
  UserIdConfig,
  UserLoginConfiguration,
  ValidateTokenResult,
  ValidateUserByEmailResult,
  VerifyEmailRequest,
  VerifyPhoneRequest,
} from './types';

// NOT WORKING, WHY?
const { crossClientLoginAndLogoutEnabled } = contextualConfig.application;

type Api = typeof defaultAuthApi;

class AuthenticationService implements IsAuthenticationService {
  api: Api;

  clientFingerprint: ClientFingerprint;

  cache: CacheInterface | StoreInterface;

  applicationId: string;

  tanEmailConfiguration: U.Nullable<EmailConfiguration>;

  requestEmailVerificationEmailConfiguration: U.Nullable<EmailConfiguration>;

  requestPhoneVerificationSmsConfiguration: U.Nullable<SmsConfiguration>;

  recoverPasswordEmailConfiguration: U.Nullable<EmailConfiguration>;

  recoverUsernameEmailConfiguration: U.Nullable<EmailConfiguration>;

  unblockAccountEmailConfiguration: U.Nullable<EmailConfiguration>;

  accessTokenHeaderName: string;

  allowedRoles: U.Nullable<string[]>;

  allowedRoleStatus: U.Nullable<RoleStatus>;

  allowedSalesOrgBrands: U.Nullable<SalesOrgBrand[]>;

  allowedScopeNames: U.Nullable<SecurityScopeNames[]>;

  requiredPermission: U.Nullable<string>;

  defaultScopeName?: SecurityScopeNames;

  constructor({
    api,
    clientFingerprint,
    cache,
    applicationId,
    accessTokenHeaderName,
    tanEmailConfiguration,
    unblockAccountEmailConfiguration,
    requestEmailVerificationEmailConfiguration,
    requestPhoneVerificationSmsConfiguration,
    recoverPasswordEmailConfiguration,
    recoverUsernameEmailConfiguration,
    allowedRoles,
    allowedRoleStatus,
    allowedSalesOrgBrands,
    allowedScopeNames,
    requiredPermission,
    defaultScopeName,
  }: AuthenticationServiceOptions) {
    this.api = api || defaultAuthApi;
    this.clientFingerprint = clientFingerprint;
    this.cache = cache;
    this.applicationId = applicationId;
    this.tanEmailConfiguration = tanEmailConfiguration || null;
    this.unblockAccountEmailConfiguration =
      unblockAccountEmailConfiguration || null;
    this.requestEmailVerificationEmailConfiguration =
      requestEmailVerificationEmailConfiguration;
    this.requestPhoneVerificationSmsConfiguration =
      requestPhoneVerificationSmsConfiguration;
    this.recoverPasswordEmailConfiguration = recoverPasswordEmailConfiguration;
    this.recoverUsernameEmailConfiguration = recoverUsernameEmailConfiguration;
    this.accessTokenHeaderName =
      accessTokenHeaderName || DEFAULT_ACCESS_TOKEN_HEADER_NAME;
    this.allowedRoles = allowedRoles || null;
    this.allowedRoleStatus = allowedRoleStatus || null;
    this.allowedSalesOrgBrands = allowedSalesOrgBrands || null;
    this.allowedScopeNames = allowedScopeNames || null;
    this.requiredPermission = requiredPermission || null;
    this.defaultScopeName =
      defaultScopeName || SecurityScopeNames.NON_CONSUMERS;
    autoBind(this);
  }

  async getDeviceId(): Promise<string> {
    try {
      return await this.clientFingerprint.getClientFingerprint();
    } catch (error) {
      logger.error('Error while trying to get device id, error=%O', error);
      return 'N/A';
    }
  }

  async handleRedirectionSuccess(
    data: U.Nullable<AuthenticationResponse>,
    callbackUrl: U.Nullable<string>,
    disableAuthRedirection?: boolean,
  ) {
    function isDomainDifferent(url: string): boolean {
      try {
        // @ts-ignore
        const urlA = new URL(window.location);
        const urlB = new URL(url);
        return urlA.origin !== urlB.origin;
      } catch (e) {
        return false;
      }
    }

    function redirect() {
      if (data?.redirectUrl) {
        window.location.replace(data?.redirectUrl);
      }
    }

    function redirectWhenPossibleCorsError() {
      if (!isEmpty(data)) {
        const { accessToken, refreshToken, redirectUrl } = data;
        const redirectOnLogin = callbackUrl;

        const url = new URL(redirectOnLogin as string);
        url.searchParams.append('accessToken', accessToken as string);
        url.searchParams.set('refreshToken', refreshToken as string);
        url.searchParams.set('redirectUrl', redirectUrl as string);
        window.location.replace(url.toString());
      }
    }

    try {
      if (!callbackUrl) {
        if (!disableAuthRedirection) {
          redirect();
        }
        return;
      }
      if (
        data?.redirectUrl &&
        isDomainDifferent(data?.redirectUrl as string) &&
        !disableAuthRedirection
      ) {
        redirectWhenPossibleCorsError();
        return;
      }

      this.api
        .post(callbackUrl, data, { withCredentials: true })
        .then(() => {
          if (!disableAuthRedirection) {
            redirect();
          }
        })
        .catch(() => {
          if (!disableAuthRedirection) {
            redirectWhenPossibleCorsError();
          }
        });
    } catch (error) {
      logger.error(
        'Failed on trying to redirect user after login or logout, error=%O',
        error,
      );
    }
  }

  async authenticate({
    username,
    ...otherProps
  }: AuthenticationRequest): Promise<AuthenticationResponse> {
    logger.debug(`Trying to authenticate username=${username}`);
    let result;
    try {
      const deviceId = await this.getDeviceId();
      const applicationId = otherProps.applicationId || this.applicationId;
      const disableAuthRedirection = otherProps.disableAuthRedirection || false;
      const response: ApiResponse<AuthenticationResponse> = await this.api.post(
        AuthenticatePath(),
        {
          username,
          applicationId,
          deviceId,
          ...otherProps,
        },
        // @ts-ignore
        RETAIL_CLIENT_REFERER_HEADERS,
      );
      result = response.data;
      logger.info(
        'Successfully got response from auth as success=%s',
        result.success,
      );
      // Added the flag crossClientLoginAndLogoutEnabled to use this only on retail-client for now
      if (
        result.success &&
        crossClientLoginAndLogoutEnabled &&
        !isDevelopment()
      ) {
        await this.handleRedirectionSuccess(
          result,
          result.onLoginUrl,
          disableAuthRedirection,
        );
      }
    } catch (error) {
      logger.error('Error when trying to authenticate, error=%O', error);
      throw new UnknownError(error);
    }
    await this.handleAuthenticationResponse(result);

    return result;
  }

  async authenticateWithGoogle({
    googleJwtToken,
    ...otherProps
  }: GoogleAuthenticationRequest): Promise<AuthenticationResponse> {
    logger.debug(
      `Trying to authenticate with google by token=${googleJwtToken}`,
    );
    let result;
    try {
      const deviceId = await this.getDeviceId();
      const applicationId = otherProps.applicationId || this.applicationId;
      const response: ApiResponse<AuthenticationResponse> = await this.api.post(
        GoogleTokenAuthPath(),
        {
          applicationId,
          deviceId,
          recaptchaType: otherProps.recaptchaType || 'V2',
          ...otherProps,
        },
        // RETAIL_CLIENT_REFERER_HEADERS,
      );
      result = response.data;
      logger.info(
        'Successfully got response from google auth as success=%s',
        result.success,
      );
    } catch (error) {
      logger.error(
        'Error when trying to authenticate by google, error=%O',
        error,
      );
      throw new UnknownError(error);
    }
    await this.handleAuthenticationResponse(result);
    return result;
  }

  async unblock({
    username,
    recaptchaResponse,
    language,
    recaptchaType,
    applicationId,
    scopeName,
    redirectUrl,
    ...params
  }: UnblockRequest & {
    language: string;
  }): Promise<UnblockResponse> {
    logger.debug(`Trying to unblock username=${username}`);
    try {
      const deviceId = await this.getDeviceId();
      const { applicationId: currentAppId, unblockAccountEmailConfiguration } =
        this;
      const request = {
        username,
        deviceId,
        applicationId: applicationId || currentAppId,
        recaptchaResponse,
        recaptchaType: recaptchaType || 'V2',
        language,
        scopeName: scopeName || this.defaultScopeName,
        emailConfiguration: unblockAccountEmailConfiguration,
        redirectUrl: Container.has(AUTHENTICATION_EMAIL_APPLICATION_BASE_URL)
          ? Container.get(AUTHENTICATION_EMAIL_APPLICATION_BASE_URL)
          : redirectUrl,
        ...params,
      };
      const url = RequestUnblockPath();
      const response: ApiResponse<UnblockResponse> = await this.api.post(
        url,
        request,
        // @ts-ignore
        RETAIL_CLIENT_REFERER_HEADERS,
      );
      logger.info(
        'Successfully got unblock response from auth as success=%s',
        JSON.stringify(response.data),
      );
      return response.data;
    } catch (error) {
      logger.error('Error when trying to unblock account, error=%O', error);
      throw new UnknownError(error);
    }
  }

  validateAllowedRoleStatus(result: AuthenticationResponse): void {
    if (!this.allowedRoleStatus) {
      return;
    }
    function getEnabledRoleStatus(
      roleStatus?: RoleStatus,
    ): (keyof RoleStatus)[] {
      if (!roleStatus) return [];
      return chain(keys(roleStatus) as (keyof RoleStatus)[])
        .filter(fieldName => roleStatus && roleStatus[fieldName])
        .value();
    }
    const enabledRoleStatus = getEnabledRoleStatus(this.allowedRoleStatus);
    const receivedRoleStatus = getEnabledRoleStatus(result.roleStatus);
    const matched = enabledRoleStatus.every(s =>
      receivedRoleStatus.includes(s),
    );
    if (!matched) {
      logger.info(
        'Couldnt match role status for restriction=%O and given=%O',
        this.allowedRoleStatus,
        result.roleStatus,
      );
      throw new MissingAuthorityError('MISSING_AUTHORITY');
    }
  }

  async validateAllowedRoles(result: AuthenticationResponse): Promise<void> {
    if (!this.allowedRoles) {
      return;
    }
    const { accessToken } = result;
    const { authorities: userAuthorities } =
      await tokenParser.parseToken(accessToken);

    function hasRole(authorities: Array<string>, roleName: string) {
      const authName = `ROLE_${roleName}`;
      return find(authorities, authority => authority === authName);
    }
    const matchedAuthorities = filter(this.allowedRoles, roleName =>
      hasRole(userAuthorities, roleName),
    );

    if (isEmpty(matchedAuthorities)) {
      logger.info(
        'Couldnt match authorities for restriction=%O and given=%O',
        this.allowedRoles,
        userAuthorities,
      );
      throw new MissingAuthorityError('MISSING_AUTHORITY');
    }
  }

  async validateAllowedScopeNames(
    result: AuthenticationResponse,
  ): Promise<void> {
    if (!this.allowedScopeNames) {
      return;
    }
    const { accessToken } = result;
    const { scopeName } = await tokenParser.parseToken(accessToken);
    if (!includes(this.allowedScopeNames, scopeName)) {
      logger.info(
        'Couldnt match scope names for restriction=%O and given=%O',
        this.allowedScopeNames,
        scopeName,
      );
      throw new UserScopeNameNotAllowedError();
    }
  }

  async validateAllowedSalesOrgBrands(
    result: AuthenticationResponse,
  ): Promise<void> {
    if (!this.allowedSalesOrgBrands || isEmpty(this.allowedSalesOrgBrands)) {
      return;
    }
    const { accessToken } = result;
    const { salesOrgBrandsIDs: userSalesOrgBrandsIDs } =
      await tokenParser.parseToken(accessToken);

    const matchedSobs = filter(this.allowedSalesOrgBrands, sob =>
      includes(userSalesOrgBrandsIDs, sob),
    );
    if (includes(this.allowedSalesOrgBrands, -1)) {
      return;
    }

    if (isEmpty(matchedSobs)) {
      logger.info(
        'Couldnt match sobs for restriction=%O and given=%O',
        this.allowedSalesOrgBrands,
        userSalesOrgBrandsIDs,
      );
      throw new MissingSalesOrgBusinessRelationError();
    }
  }

  async validateRequiredPermission(
    result: AuthenticationResponse,
  ): Promise<void> {
    if (!this.requiredPermission) {
      return;
    }
    const { accessToken } = result;
    const { authorities } = await tokenParser.parseToken(accessToken);

    const foundPermission = find(
      authorities,
      authority => authority === `PERM_${this.requiredPermission}`,
    );
    if (this.requiredPermission && isEmpty(foundPermission)) {
      logger.info('Couldnt find permission %O', this.requiredPermission);
      throw new MissingAuthorityError();
    }
  }

  async handleAuthenticationResponse(
    result: AuthenticationResponse,
  ): Promise<void> {
    if (result.success) {
      this.validateAllowedRoleStatus(result);
      await this.validateAllowedRoles(result);
      await this.validateAllowedSalesOrgBrands(result);
      await this.validateRequiredPermission(result);
      await this.validateAllowedScopeNames(result);
      await this.setAccessToken(result.accessToken);
      await this.setRefreshToken(result.refreshToken);
      await this.setLoginCallbackUrl(result.onLoginUrl);
      await this.setLogoutCallbackUrl(result.onLogoutUrl);
    }
    if (!result.error) {
      return;
    }
    this.handleAuthenticationErrorResponse(result.error);
  }

  // eslint-disable-next-line class-methods-use-this
  handleAuthenticationErrorResponse(error: string): void {
    switch (error) {
      case 'CAPTCHA_BLOCKED': {
        throw new CaptchaBlockedError('CAPTCHA_BLOCKED');
      }
      case 'EMAIL_BLOCKED': {
        throw new EmailBlockedError('EMAIL_BLOCKED');
      }
      case 'BAD_CREDENTIALS': {
        throw new BadCredentialsError('BAD_CREDENTIALS');
      }
      case 'MISSING_AUTHORITY': {
        throw new MissingAuthorityError();
      }
      case 'INVALID_APPLICATION': {
        throw new InvalidApplicationError();
      }
      default: {
        throw new UnknownError();
      }
    }
  }

  async refreshAuthentication(): Promise<RefreshAuthenticationResponse> {
    logger.debug('Trying to refresh authentication');
    let result;
    try {
      const refreshToken = await this.getRefreshToken();
      const response: ApiResponse<RefreshAuthenticationResponse> =
        await this.api.post(RefreshAuthenticationPath(), {
          refreshToken,
        });
      result = response.data;
      logger.info(
        'Successfully got response from refresh auth as success=%s',
        result.success,
      );
    } catch (error) {
      logger.error('Error when trying to refresh auth, error=%O', error);
      throw new UnknownError(error);
    }
    if (result.success && result.accessToken) {
      await this.setAccessToken(result.accessToken);
    }
    return result;
  }

  async logout(): Promise<void> {
    logger.debug('Trying to logout');
    let result;
    try {
      const refreshToken = await this.getRefreshToken();
      const response: ApiResponse<AuthenticationResponse> = await this.api.post(
        LogoutPath(),
        {
          refreshToken,
        },
      );
      result = response.data;
      await this.setRefreshToken('');
      await this.setAccessToken('');
      logger.info('Logged out with error=%s', result.error);
      // Added the flag crossClientLoginAndLogoutEnabled to use this only on retail-client for now
      if (
        result.success &&
        crossClientLoginAndLogoutEnabled &&
        !isDevelopment()
      ) {
        const logoutUrl = await this.getLogoutCallbackUrl();
        const authData = {
          ...result,
          accessToken: await this.getAccessToken(),
          refreshToken: await this.getRefreshToken(),
        };
        await this.handleRedirectionSuccess(authData, logoutUrl);
        await this.setLogoutCallbackUrl('');
        await this.setLoginCallbackUrl('');
      }
    } catch (error) {
      logger.error('Error when trying to logout, error=%O', error);
      throw new UnknownError(error);
    }
  }

  async logoutWithAppId(props: {
    applicationId?: U.Nullable<string>;
    applicationBaseUrl?: U.Nullable<string>;
    applicationPath?: U.Nullable<string>;
    applicationContextPath?: U.Nullable<string>;
    global?: U.Nullable<boolean>;
    logoutOthers?: U.Nullable<Record<string, unknown>>;
  }): Promise<void> {
    logger.debug('Trying to logout');
    let result;
    try {
      const refreshToken = await this.getRefreshToken();
      const response: ApiResponse<AuthenticationResponse> = await this.api.post(
        LogoutPath(),
        {
          refreshToken,
          ...props,
        },
      );
      result = response.data;
      await this.setRefreshToken('');
      await this.setAccessToken('');
      logger.info('Logged out with error=%s', result.error);
    } catch (error) {
      logger.error('Error when trying to logout, error=%O', error);
      throw new UnknownError(error);
    }
  }

  async requestTan({
    username,
    isReSend,
    ...otherProps
  }: RequestTanRequest): Promise<void> {
    logger.debug(`Trying to request tan for username=${username}`);
    let result;
    try {
      const deviceId = await this.getDeviceId();
      const { applicationId, tanEmailConfiguration } = this;
      const response: ApiResponse<OperationResult> = await this.api.post(
        RequestTanPath(),
        {
          username,
          applicationId,
          deviceId,
          emailConfiguration: tanEmailConfiguration,
          ...otherProps,
        },
        // @ts-ignore
        RETAIL_CLIENT_REFERER_HEADERS,
      );
      result = response.data;
    } catch (error) {
      logger.error('Error when trying request tan, error=%O', error);
      throw new UnknownError(error);
    }
    if (result.success) {
      logger.info('Successfully got response from tan req as success');
    } else {
      logger.info('Tan request not successfull, possibly email not found');
      if (isReSend) {
        throw new TanReSendRequestNotSuccessfullError(result.errorCode);
      } else {
        throw new TanRequestNotSuccessfullError(result.errorCode);
      }
    }
  }

  async authenticateByTan({
    username,
    tan,
    ...otherProps
  }: AuthenticationByTanRequest): Promise<AuthenticationResponse> {
    logger.debug(`Trying to authenticate by tan username=${username}`);
    let result;
    try {
      const deviceId = await this.getDeviceId();
      const { applicationId } = this;
      const response: ApiResponse<AuthenticationResponse> = await this.api.post(
        AuthenticateByTanPath(),
        {
          username,
          applicationId,
          deviceId,
          tan: tan.toUpperCase(),
          ...otherProps,
        },
        // @ts-ignore
        RETAIL_CLIENT_REFERER_HEADERS,
      );
      result = response.data;
      logger.info(
        'Successfully got response from auth by tan as success=%s',
        result.success,
      );
    } catch (error) {
      logger.error('Error when trying to authenticate by tan, error=%O', error);
      throw new UnknownError(error);
    }
    await this.handleAuthenticationResponse(result);
    return result;
  }

  async getAccessToken(): Promise<U.Nullable<string>> {
    return this.cache.get(ACCESS_TOKEN_KEY);
  }

  async getRefreshToken(): Promise<U.Nullable<string>> {
    return this.cache.get(REFRESH_TOKEN_KEY);
  }

  async setAccessToken(token: U.Nullable<string>): Promise<void> {
    // @ts-ignore
    await this.cache.set(ACCESS_TOKEN_KEY, token);
  }

  async setRefreshToken(token: U.Nullable<string>): Promise<void> {
    // @ts-ignore
    await this.cache.set(REFRESH_TOKEN_KEY, token);
  }

  async getLoginCallbackUrl(): Promise<U.Nullable<string>> {
    return this.cache.get(LOGIN_CALLBACK_URL_STORAGE_KEY);
  }

  async getLogoutCallbackUrl(): Promise<U.Nullable<string>> {
    return this.cache.get(LOGOUT_CALLBACK_URL_STORAGE_KEY);
  }

  async setLoginCallbackUrl(
    loginCallbackUrl: U.Nullable<string>,
  ): Promise<void> {
    // @ts-ignore
    await this.cache.set(LOGIN_CALLBACK_URL_STORAGE_KEY, loginCallbackUrl);
  }

  async setLogoutCallbackUrl(
    logoutCallbackUrl: U.Nullable<string>,
  ): Promise<void> {
    // @ts-ignore
    await this.cache.set(LOGOUT_CALLBACK_URL_STORAGE_KEY, logoutCallbackUrl);
  }

  static async isTokenValid(token: U.Nullable<string>): Promise<boolean> {
    if (!token) {
      logger.trace('Access token is empty');
      return false;
    }
    const expired = await tokenParser.isTokenExpired(token);
    return !expired;
  }

  async isAccessTokenValid(): Promise<boolean> {
    const token = await this.getAccessToken();
    return AuthenticationService.isTokenValid(token);
  }

  async isRefreshTokenValid(): Promise<boolean> {
    const token = await this.getRefreshToken();
    return AuthenticationService.isTokenValid(token);
  }

  async getUserDetails(): Promise<TokenParseResult | null> {
    const accessToken = await this.getAccessToken();
    if (!accessToken) {
      return null;
    }
    return tokenParser.parseToken(accessToken);
  }

  async authenticateBySSOToken({
    ssoToken,
    ...rest
  }: {
    ssoToken: string;
    applicationId?: U.Nullable<string>;
    applicationBaseUrl?: U.Nullable<string>;
    applicationPath?: U.Nullable<string>;
    applicationContextPath?: U.Nullable<string>;
  }): Promise<AuthenticationResponse> {
    logger.debug('Trying to authenticate by sso token');
    let result: AuthenticationResponse | null = null;
    try {
      const deviceId = await this.getDeviceId();
      const { applicationId } = this;
      const response: ApiResponse<AuthenticationResponse> = await this.api.post(
        AuthenticateBySSOTokenPath(),
        {
          ssoToken,
          applicationId: applicationId || this.applicationId,
          deviceId,
          ...rest,
        },
      );
      result = response.data;
      logger.info(
        'Successfully got response from auth by sso token success=%s',
        result.success,
      );
    } catch (error) {
      logger.error(
        'Error when trying to authenticate by sso token, error=%O',
        error,
      );
      throw new UnknownError(error);
    }
    await this.handleAuthenticationResponse(result);
    return result;
  }

  async isAuthenticatedBySystemToken(): Promise<boolean> {
    const isValidSystemToken = await this.hasValidSystemToken();
    if (!isValidSystemToken) {
      return false;
    }
    try {
      const ssoTokenResult = await this.generateSSOTokenBySystemToken({});
      const { ssoToken } = ssoTokenResult;
      if (!ssoTokenResult.success || ssoToken == null) {
        return false;
      }
      const result = await this.authenticateBySSOToken({ ssoToken });
      return result.success;
    } catch (error) {
      logger.error(
        'Error when checking if auth by system token, error=%O',
        error,
      );
      return false;
    }
  }

  async hasValidSystemToken(): Promise<boolean> {
    logger.debug('Trying to verify system token');
    let result;
    try {
      const response: ApiResponse<ValidateTokenResult> = await this.api.get(
        ValidateSystemTokenPath(),
      );
      result = response.data;
      logger.info(
        'Successfully got system token verification success=%s and error=%s',
        result.success,
        result.error,
      );
      return result.success;
    } catch (error) {
      logger.error(
        'Error when trying to authenticate by system token, error=%O',
        error,
      );
      return false;
    }
  }

  async generateSSOTokenBySystemToken(props: {
    applicationId?: U.Nullable<string>;
    applicationBaseUrl?: U.Nullable<string>;
    applicationPath?: U.Nullable<string>;
    applicationContextPath?: U.Nullable<string>;
    ttl?: U.Nullable<number>;
  }): Promise<GenerateSSOTokenResult> {
    logger.debug('Trying to generate sso token');
    let result;
    try {
      const { applicationId } = this;
      const response: ApiResponse<GenerateSSOTokenResult> = await this.api.post(
        GenerateSSOTokenBySystemTokenPath(),
        {
          applicationId,
          ...props,
        },
      );
      result = response.data;
      logger.info(
        'Successfully got response from generate sso token as success=%s',
        result.success,
      );
      return result;
    } catch (error) {
      logger.error('Error when trying to generate sso token, error=%O', error);
      throw new UnknownError(error);
    }
  }

  async generateSSOTokenByRefreshToken(props: {
    applicationId?: U.Nullable<string>;
    applicationBaseUrl?: U.Nullable<string>;
    applicationPath?: U.Nullable<string>;
    applicationContextPath?: U.Nullable<string>;
    ttl?: U.Nullable<number>;
    refreshToken: U.Nullable<string>;
  }): Promise<GenerateSSOTokenResult> {
    logger.debug('Trying to generate sso token by refresh token');
    let result;
    try {
      const { applicationId } = this;
      const response: ApiResponse<GenerateSSOTokenResult> = await this.api.post(
        GenerateSSOTokenByRefreshTokenPath(),
        {
          applicationId,
          ...props,
        },
      );
      result = response.data;
      logger.info(
        'Successfully got response from generate sso token by refresh token as success=%s',
        result.success,
      );
      return result;
    } catch (error) {
      logger.error(
        'Error when trying to generate sso token by refresh token, error=%O',
        error,
      );
      throw new UnknownError(error);
    }
  }

  async sendAuthenticationEmail(
    request: SendAuthenticationEmailRequest,
  ): Promise<{ success: boolean; errorCode: null }> {
    const config = await this.getLoginConfigForEmailOrUserid(request.username);
    logger.debug('Trying to send authentication email');
    try {
      const { applicationId } = this;
      const response: ApiResponse<{ success: boolean; errorCode: null }> =
        await this.api.post(SendAuthenticationEmailPath(), {
          ...request,
          username: config.userid || request.username,
          applicationId: request.applicationId || applicationId,
        });
      logger.info(
        'Successfully got response from generate sso token as success=%s',
        response.data.success,
      );
      return response.data;
    } catch (error) {
      logger.error(
        'Error when trying to authenticate by sso token, error=%O',
        error,
      );
      throw new UnknownError(error);
    }
  }

  async isAuthBySystemTokenThenGenerateSSO({
    applicationId,
    applicationBaseUrl,
    applicationPath,
    applicationContextPath,
  }: {
    applicationId?: U.Nullable<string>;
    applicationBaseUrl?: U.Nullable<string>;
    applicationPath?: U.Nullable<string>;
    applicationContextPath?: U.Nullable<string>;
  }): Promise<boolean> {
    try {
      const ssoTokenResult = await this.generateSSOTokenBySystemToken({
        applicationId,
        applicationBaseUrl,
        applicationPath,
        applicationContextPath,
      });
      const { ssoToken } = ssoTokenResult;
      if (!ssoTokenResult.success || ssoToken == null) {
        return false;
      }
      const result = await this.authenticateBySSOToken({
        ssoToken,
        applicationId,
        applicationBaseUrl,
        applicationPath,
        applicationContextPath,
      });
      return result.success;
    } catch (error) {
      logger.error(
        'Error when checking if auth by system token, error=%O',
        error,
      );
      return false;
    }
  }

  async isAuthenticated({
    fetchApi = false,
  }: IsAuthenticatedOptions): Promise<boolean> {
    logger.debug('Trying to verify if user is authenticated');
    const accessTokenValid = await this.isAccessTokenValid();
    const refreshTokenValid = await this.isRefreshTokenValid();
    if (!accessTokenValid && !refreshTokenValid) {
      logger.debug('No valid access or refresh token found, not authenticated');
      return fetchApi ? this.isAuthenticatedBySystemToken() : false;
    }
    if (accessTokenValid && refreshTokenValid) {
      return true;
    }
    if (refreshTokenValid && fetchApi) {
      const result = await this.refreshAuthentication();
      if (result.success) {
        return true;
      }
    }
    return false;
  }

  async isLoggedIn(): Promise<boolean> {
    return this.isAuthenticated({});
  }

  async ensureLogin(...messages: any[]) {
    // do not add try/catch
    if (!(await this.isLoggedIn()))
      throw new AuthenticationRequiredError(messages.map(String).join());
  }

  async getAuthenticationHttpHeaders(): Promise<AuthenticationHeaders> {
    // checks if authenticated, if not, tries to re-auth]
    const isValid = await this.isAccessTokenValid();
    if (!isValid) {
      logger.warn('Current access token not valid, will try to check auth');
      await this.isAuthenticated({ fetchApi: true });
    }
    const accessToken = await this.getAccessToken();
    if (!accessToken) {
      logger.warn(
        'Current access token not valid or empty, cant send auth headers! Will cause logout.',
      );
      return {};
    }
    return {
      [this.accessTokenHeaderName]: accessToken,
    };
  }

  getAccessTokenFromHttpHeaders(
    headers: AuthenticationHeaders,
  ): U.Nullable<string> {
    return headers ? headers[this.accessTokenHeaderName] : null;
  }

  static handleRetrieveUseridResponse(result: ValidateUserByEmailResult): void {
    if (!result.error) {
      return;
    }
    switch (result.error) {
      case 'USER_DUPLICATED': {
        throw new DuplicatedEmailError('USER_DUPLICATED');
      }
      case 'USER_NOT_FOUND': {
        throw new UserNotFoundError('USER_NOT_FOUND');
      }
      default: {
        throw new UnknownError();
      }
    }
  }

  async retrieveUserid(
    email: string,
    scopeNames?: SecurityScopeNames[],
  ): Promise<
    (UserIdConfig & { success: boolean; error: string | null }) | string
  > {
    logger.debug(`Trying to find username by email=${email}`);
    let result;
    try {
      const deviceId = await this.getDeviceId();
      const { applicationId, allowedRoles } = this;
      if (!allowedRoles) {
        logger.error('Allowed roles is empty');
      }
      const response: ApiResponse<ValidateUserByEmailResult> =
        await this.api.post(GetUseridByEmailPath(), {
          email,
          applicationId,
          deviceId,
          roles: allowedRoles,
          salesOrgBrandIDs: this.allowedSalesOrgBrands,
          scopeNames,
        });
      result = response.data;
    } catch (error) {
      logger.error(
        'Error when trying to find username by email, error=%O',
        error,
      );
      throw new UnknownError(error);
    }
    AuthenticationService.handleRetrieveUseridResponse(result);
    if (!isEmpty(scopeNames)) {
      return {
        userid: result.userid,
        scopeName: result.scopeName,
        success: result.success,
        error: result.error,
      };
    }
    /// XXX: exception is thrown in failure error
    return result?.userid as string;
  }

  async isEmailValidForRegistration(email: string): Promise<boolean> {
    logger.debug(`Will try to validate email=${email}`);
    try {
      const userID = await this.retrieveUserid(email);
      if (userID) {
        logger.debug(`Email=${email} already registered to userID=${userID}`);
        return false;
      }
      logger.error(`Error while trying to validate email=${email}`);
      throw new UnknownError('');
    } catch (error) {
      switch (true) {
        case error instanceof UserNotFoundError:
          logger.debug(`Email=${email} is not registered, error=%O`, error);
          return true;
        case error instanceof DuplicatedEmailError:
          logger.debug(
            `Email=${email} already registered for other users, error=%O`,
            error,
          );
          return false;
        default:
          return error;
      }
    }
  }

  async getLoginConfigForEmailOrUserid(
    givenEmailOrUserid: string,
  ): Promise<UserLoginConfiguration> {
    logger.debug(`Will try to detect login config for=${givenEmailOrUserid}`);
    const emailOrUserid = trim(givenEmailOrUserid);
    if (isEmpty(emailOrUserid)) {
      return emptyUserLoginConfiguration();
    }
    const isEmail = emailRegexTest(emailOrUserid);
    let userid: U.Nullable<UserIdConfig | string> = isEmail
      ? null
      : emailOrUserid;
    const email = isEmail ? emailOrUserid : null;
    if (email) {
      try {
        userid = await this.retrieveUserid(email);
      } catch (error) {
        userid = null;
      }
    }
    const isFound = isEmail ? !isEmpty(userid) : true; // TODO: fetch userid and see if it exists
    // TODO: create an API to map all of this
    const config = {
      found: isFound,
      userid: isObject(userid) ? userid.userid : userid,
      email,
      allowLoginByPassword: !isEmpty(userid),
      allowLoginByTan: !isEmpty(email),
    };
    logger.debug('Detected login config as', config);
    return config;
  }

  async requestEmailVerification({
    email,
    emailConfiguration,
    ...otherProps
  }: RequestEmailVerificationRequest): Promise<OperationResult> {
    logger.debug(`Trying to request email verification for=${email}`);
    let result;
    try {
      const deviceId = await this.getDeviceId();
      const { applicationId } = this;
      const response: ApiResponse<AuthenticationResponse> = await this.api.post(
        RequestEmailVerificationPath(),
        {
          email,
          applicationId,
          deviceId,
          emailConfiguration: {
            ...this.requestEmailVerificationEmailConfiguration,
            ...(emailConfiguration || {}),
          },
          roles: this.allowedRoles,
          ...otherProps,
        },
        // @ts-ignore
        RETAIL_CLIENT_REFERER_HEADERS,
      );
      result = response.data;
      logger.info(
        'Successfully got response from request email verification as success=%s',
        result.success,
      );
    } catch (error) {
      logger.error(
        'Error when trying to request email verification, error=%O',
        error,
      );
      throw new UnknownError(error);
    }
    return result;
  }

  async verifyEmail({
    email,
    operationId,
    ...otherProps
  }: VerifyEmailRequest): Promise<TwoFactorAuthResult> {
    logger.debug(`Trying to verify email confirmation for=${email}`);
    let result;
    try {
      const deviceId = await this.getDeviceId();
      const { applicationId } = this;
      const response: ApiResponse<TwoFactorAuthResult> = await this.api.post(
        VerifyEmailPath(),
        {
          email,
          operationId,
          applicationId,
          deviceId,
          ...otherProps,
        },
        // @ts-ignore
        RETAIL_CLIENT_REFERER_HEADERS,
      );
      result = response.data;
      logger.info(
        'Successfully got response from verify email as success=%s',
        result.success,
      );
    } catch (error) {
      logger.error('Error when trying to verify email, error=%O', error);
      throw new UnknownError(error);
    }
    if (result.error) {
      this.handleAuthenticationErrorResponse(result.error);
    }
    return result;
  }

  async requestPhoneVerificationBySms({
    phone,
    country,
    ...otherProps
  }: RequestPhonelVerificationRequest): Promise<OperationResult> {
    logger.debug(`Trying to request phone verification for=${phone}`);
    let result;
    try {
      const deviceId = await this.getDeviceId();
      const { applicationId } = this;
      const response: ApiResponse<AuthenticationResponse> = await this.api.post(
        RequestUserVerificationBySmsPath(),
        {
          phone,
          country,
          applicationId,
          deviceId,
          smsConfiguration: this.requestPhoneVerificationSmsConfiguration,
          ...otherProps,
        },
        // @ts-ignore
        RETAIL_CLIENT_REFERER_HEADERS,
      );
      result = response.data;
      logger.info(
        'Successfully got response from request phone verification as success=%s',
        result.success,
      );
    } catch (error) {
      logger.error(
        'Error when trying to request phone verification, error=%O',
        error,
      );
      throw new UnknownError(error);
    }
    return result;
  }

  async verifyPhone({
    phone,
    country,
    operationId,
    ...otherProps
  }: VerifyPhoneRequest): Promise<TwoFactorAuthResult> {
    logger.debug(`Trying to verify phone confirmation for=${phone}`);
    let result;
    try {
      const deviceId = await this.getDeviceId();
      const { applicationId } = this;
      const response: ApiResponse<TwoFactorAuthResult> = await this.api.post(
        VerifyPhonePath(),
        {
          phone,
          country,
          operationId,
          applicationId,
          deviceId,
          ...otherProps,
        },
        // @ts-ignore
        RETAIL_CLIENT_REFERER_HEADERS,
      );
      result = response.data;
      logger.info(
        'Successfully got response from verify phone as success=%s',
        result.success,
      );
    } catch (error) {
      logger.error('Error when trying to verify phone, error=%O', error);
      throw new UnknownError(error);
    }
    if (result.error) {
      this.handleAuthenticationErrorResponse(result.error);
    }
    return result;
  }

  async recoverPassword({
    userid,
    language,
    customConfigs,
  }: {
    userid: string;
    language: LanguageCode;
    customConfigs?: Partial<RecoverPasswordBaseParams>;
  }): Promise<{ success: boolean }> {
    logger.debug(`Trying to recover password for=${userid}`);
    if (!this.recoverPasswordEmailConfiguration) {
      logger.error('recoverPasswordEmailConfiguration not configured');
      throw new Error('recoverPasswordEmailConfiguration not configured');
    }
    const config: BaseEmailConfiguration =
      this.recoverPasswordEmailConfiguration;
    try {
      await this.api.post(
        RecoverPasswordPath(userid, language, config, customConfigs),
      );
      logger.info('Successfully got response from recover password as success');
      return { success: true };
    } catch (error) {
      logger.error('Error when trying to recover password, error=%O', error);
      throw new UnknownError(error);
    }
  }

  async recoverUsername(
    email: string,
    language: LanguageCode,
  ): Promise<{ success: boolean }> {
    logger.debug(`Trying to recover username for=${email}`);
    if (!this.recoverUsernameEmailConfiguration) {
      logger.error('recoverUsernameEmailConfiguration not configured');
      throw new Error('recoverUsernameEmailConfiguration not configured');
    }
    const config: BaseEmailConfiguration =
      this.recoverUsernameEmailConfiguration;

    try {
      await this.api.post(RecoverUsernamePath(email, language, config));
      logger.info('Successfully got response from recover username as success');
      return { success: true };
    } catch (error) {
      logger.error('Error when trying to recover username, error=%O', error);
      throw new UnknownError(error);
    }
  }

  async validateChangePasswordUuid(uuid: string): Promise<OperationResult> {
    logger.debug('Trying to validate uuid for change password');
    let result;
    try {
      const response = await this.api.post(ValidateChangePasswordUuidPath(), {
        uuid,
      });
      result = response.data;
      logger.info(
        'Successfully validate change password uuid as success=%s',
        result.success,
      );
    } catch (error) {
      logger.error(
        'Error when trying to validate change password uuid, error=%O',
        error,
      );
      throw new UnknownError(error);
    }
    return result;
  }

  async changePassword({
    uuid,
    password,
    language,
  }: {
    uuid: string;
    password: string;
    language: LanguageCode;
  }): Promise<void> {
    logger.debug('Trying to change password');
    try {
      await this.api.post(ChangePasswordPath(language), {
        uuid,
        password,
      });
      logger.info('Successfully changed password');
    } catch (error) {
      logger.error('Error when trying to change password, error=%O', error);
      throw new UnknownError(error);
    }
  }

  async getUserAuthorities(): Promise<string[]> {
    return (await this.getUserDetails())?.authorities || [];
  }
}

export default AuthenticationService;

export const accessTokenQuery = (accessToken: string): string =>
  qs.stringify({
    [DEFAULT_ACCESS_TOKEN_HEADER_NAME]: accessToken,
  });
