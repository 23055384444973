import { useCookieStore } from '@cookie-consent/core/configurable-cookies-consent/state';
import { UNITED_STATES } from '@mmw/constants-country-codes';
import { REC } from '@mmw/constants-salesorgbrand-ids';
import {
  setStoreSearchDynamicParams,
  setStoresSearchParams,
} from '@store-locator/store-creator';
import {
  SearchRequestObject,
  TraderSearchSortOrder,
} from '@store-locator/store-creator/types';
import {
  InvisibleRadiusSelect,
  setCountriesWithStateEnabled,
  setMeasureUnit,
  setRadiusSelect,
} from '@store-locator/view';
import { setIsIframe } from '@store-locator/view/components/map';

setRadiusSelect(InvisibleRadiusSelect);
setMeasureUnit(() => null);
setIsIframe('true');
useCookieStore.getState().setCookieConfig('googleMaps', true);

const STORES_SEARCH_PARAMS: Partial<SearchRequestObject> = {
  salesOrgBrand: REC,
  limit: 20,
  returnsOnlyTradersRankedByBrand: true,
  returnsOnlyWithContractsNotExpired: true,
  forceFilterByBusinessRelation: true,
  sortOrder: TraderSearchSortOrder.BRAND_RANKING,
  returnsDistance: true,
};

setStoresSearchParams(STORES_SEARCH_PARAMS);

setCountriesWithStateEnabled([UNITED_STATES]);

setStoreSearchDynamicParams(request => {
  if (request.country === UNITED_STATES) {
    return {
      returnsOnlyWithProjectsOnTheLast6Months: true,
    };
  }
  return {};
});
