import { Container, Token } from 'typedi';

type Setter<DI> = (item: DI) => void;
type Getter<DI> = () => DI;

export function createDI<DI, S extends string = string>(
  name: S,
  defaultValue?: DI,
  options?: {
    acceptNull?: boolean;
  },
): [Getter<DI>, Setter<DI>, Token<DI>] {
  const TOKEN = new Token<DI>(name as string);

  function setDI(item: DI) {
    return Container.set(TOKEN, item);
  }

  function getDI(): DI {
    if (!Container.has(TOKEN))
      throw new Error(`Token was not set correctly = ${TOKEN.name}`);
    return Container.get(TOKEN);
  }

  if (defaultValue) {
    setDI(defaultValue);
  }

  if (defaultValue === null && options?.acceptNull) {
    setDI(defaultValue);
    return [getDI, setDI, TOKEN];
  }

  if (defaultValue === null && !options?.acceptNull) {
    throw new Error(`Token was not set correctly = ${TOKEN.name}`);
  }

  return [getDI, setDI, TOKEN];
}
