/* eslint-disable class-methods-use-this */
import base64url from 'base64url';
import { Buffer } from 'buffer';
import createCache from 'keshi';
import pako from 'pako';

import { InvalidTokenError } from './errors';
import logger from './log';
import { TokenParseResult } from './types';

global.Buffer = global.Buffer || Buffer; // XXX: react-native fix

const TOKEN_CONTENT_POSITION = 1;

class TokenParser {
  cache: Object;

  constructor() {
    this.cache = createCache();
  }

  parseTokenSync(token: string): TokenParseResult {
    logger.trace('Trying to parse, token=%s', token);
    try {
      const content = token.split('.')[TOKEN_CONTENT_POSITION];
      const contentBuff = base64url.toBuffer(content);
      const claims = pako.inflate(contentBuff, {
        to: 'string',
      }); // XXX: changed flow type defs
      const json = JSON.parse(claims);
      logger.trace('Parsed token as=%O', json);
      return json;
    } catch (error) {
      logger.trace('Error while trying to parse token=%O', error);
      throw new InvalidTokenError();
    }
  }

  async parseToken<T>(token: string | void): Promise<TokenParseResult & T> {
    if (!token) {
      logger.trace('Token is empty, cant parse');
      throw new InvalidTokenError();
    }
    const cacheKey = `x-auth-access-token-${token}`;
    return this.cache.resolve(
      cacheKey,
      async () => this.parseTokenSync(token),
      '2h',
    );
  }

  getCurrentTime() {
    return Date.now() / 1000;
  }

  async isTokenExpired(token: string | void): Promise<boolean> {
    logger.debug('Trying to verify if token is expired, token=%s', token);
    if (!token) {
      logger.trace('Token is empty');
      return true;
    }
    try {
      const { exp } = await this.parseToken(token);
      const expired = exp < this.getCurrentTime();
      logger.debug('Token has expired=%s', expired);
      return expired;
    } catch (error) {
      return true;
    }
  }
}

export default TokenParser;
export const singleton = new TokenParser();
