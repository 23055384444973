import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './customCss.css';

import { CarouselType } from '@ui-system/interfaces-carousel';
import React from 'react';
import {
  Carousel as ResponsiveCarousel,
  CarouselProps,
} from 'react-responsive-carousel';

/* TODO: Move this component to the web folder,
props are very different compared the native version */

const Carousel: CarouselType = ({
  children,
  showArrows,
  showStatus,
  showThumbs,
  emulateTouch = true,
  ...props
}: CarouselProps) => (
  <ResponsiveCarousel
    showArrows={showArrows}
    showStatus={showStatus}
    showThumbs={showThumbs}
    emulateTouch={emulateTouch}
    {...props}
  >
    {children}
  </ResponsiveCarousel>
);

export default Carousel;
