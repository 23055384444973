import { I18nShape } from '@mmw/constants-i18n';
import { is } from 'fp';
import { useMemo } from 'react';
import { U } from 'ts-toolbelt';

export function useI18nWithValues(
  i18n: U.Nullable<I18nShape | string | number>,
  values: U.Nullable<Record<string, any>>,
): string | I18nShape {
  return useMemo<string | I18nShape>(() => {
    if (is(Object, i18n)) {
      return {
        ...((i18n as I18nShape) || {}),
        // @ts-ignore
        values: (i18n?.values as I18nShape['values']) || values,
      } as I18nShape;
    }
    return i18n;
  }, [i18n, values]);
}
