import { Style } from '@ui-system/interfaces/types';
import { nanoid } from 'nanoid';
import * as React from 'react';
import { ImageSourcePropType } from 'react-native';

export type AvatarSize = 'tiny' | 'small' | 'medium' | 'large' | 'giant';
export type AvatarVariant = 'circular' | 'rounded' | 'square';
export type AvatarShape = 'round' | 'rounded' | 'square';

export interface AvatarProps extends Record<string, any> {
  variant?: AvatarVariant;
  shape?: AvatarShape;
  size?: AvatarSize;
  source?: ImageSourcePropType;
  src?: string;
  style?: Style;
  loading?: boolean;
  children?: any;
}

export const AVATAR_UUID = nanoid();
export type AvatarType = React.FC<AvatarProps>;
