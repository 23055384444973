const namespace = 'messages';
const errorNamespace = 'error';
const successNamespace = 'success';

const i18n = {
  INTERNAL_ERROR: {
    key: `${namespace}.internalError`,
    message:
      'An internal error ocurred while processing your request. Please try again later.',
  },
  API_CONNECTION_ERROR: {
    key: `${namespace}.apiConnectionFailed`,
    message:
      'Server error, check your connection or try again in a few moments.',
  },
  API_CONNECTION_REESTABLISHED: {
    key: `${namespace}.apiReConnectionSuccess`,
    message: 'Server connection successfully reestablished!',
  },
  SOMETHING_WENT_WRONG_TRY_AGAIN: {
    key: `${namespace}.somethingWentWrongTryAgain`,
    message: 'Something went wrong, please try again',
  },
  REMOVE_FILE_CONFIRMATION: {
    key: `${namespace}.removeFileConfirmation`,
    message: "Are you sure you want to delete this file? This can't be undone!",
  },
  CREATE_SALES_PERSON_ERROR: {
    key: `${namespace}.createSalesPersonError`,
    message: 'Error on create new seller, this email is already in use',
  },
  FAILED_TO_LOAD_DOCUMENT: {
    key: `${namespace}.failedToLoadDocument`,
    message: 'Failed to load document',
  },
  FAILED_TO_LOAD_POSITIONS: {
    key: `${namespace}.failedToLoadPositions`,
    message: 'Failed to load available salesperson positions',
  },
  CAMPAIGN_REDIRECTION_FAILED: {
    key: `${namespace}.${errorNamespace}.campaignRedirectionFailed`,
    message: 'Failed to open campaign page',
  },
  PASSWORD_REDEFINE_SUCCESS: {
    key: `${namespace}.${successNamespace}.passwordRedefined`,
    message: 'Password successfuly redefined',
  },
  PASSWORD_REDEFINE_FAILED: {
    key: `${namespace}.${errorNamespace}.passwordRedefineFailed`,
    message: 'Failed to redefine password',
  },
  BANK_ACCOUNT_SAVED_SUCCESS: {
    key: `${namespace}.${successNamespace}.bankDataSaved`,
    message: 'Successfully saved bank account data',
  },
  FAILED_TO_SAVE_BANK_DATA: {
    key: `${namespace}.${errorNamespace}.bankDataSaveFailed`,
    message: 'Failed to save bank account data',
  },
  PERSONAL_DATA_UPDATE_SUCCESS: {
    key: `${namespace}.${successNamespace}.personalDataUpdated`,
    message: 'Successfully updated personal data',
  },
  FAILED_TO_UPDATE_PERSONAL_DATA: {
    key: `${namespace}.${errorNamespace}.personalDataUpdateFailed`,
    message: 'Failed to update personal data',
  },
  PROFILE_PICTURE_UPDATE_SUCCESS: {
    key: `${namespace}.${successNamespace}.profilePictureUpdated`,
    message: 'Successfully updated profile picture',
  },
  UPDATE_PROFILE_PICTURE_FAILED: {
    key: `${namespace}.${errorNamespace}.profilePictureUpdateFailed`,
    message: 'Failed to update profile picture',
  },
  SEND_EMAIL_SUCCESS: {
    key: `${namespace}.${successNamespace}.verificationCodeSent`,
    message: 'A verification code was sent to your email',
  },
  SEND_EMAIL_ERROR: {
    key: `${namespace}.${errorNamespace}.verificationCodeSentFailed`,
    message: 'Failed on trying to send code to your email, please try again',
  },
  EMAIL_VERIFICATION_SUCCESS: {
    key: `${namespace}.${successNamespace}.emailVerified`,
    message: 'Successfully verified email',
  },
  EMAIL_VERIFICATION_FAILED: {
    key: `${namespace}.${errorNamespace}.emailVerificationError`,
    message: 'Cant verify your email',
  },
  ACCOUNT_CREATION_ERROR: {
    key: `${namespace}.${errorNamespace}.accountCreationError`,
    message: 'Account creation process failed',
  },
  INVALID_PHONE_NUMBER: {
    key: `${namespace}.${errorNamespace}.invalidPhoneNumber`,
    message: 'Invalid phone number informed, check it and try again',
  },
  SMS_CODE_SENT: {
    SEND_SUCCESS: {
      key: `${namespace}.${successNamespace}.smsCodeSendSuccess`,
      message: 'A SMS with a validation code was sent to your phone',
    },
    FAILED_TO_SEND: {
      key: `${namespace}.${errorNamespace}.failedToSendSms`,
      message: 'Failed to send validation code by SMS, please try again later',
    },
  },
  PHONE_NUMBER_VERIFICATION: {
    VERIFICATION_ERROR: {
      key: `${namespace}.${errorNamespace}.phoneVerificationFailed`,
      message: 'Sorry, cant verify your phone number, please try again later',
    },
    SUCCESSFULLY_VERIFIED: {
      key: `${namespace}.${successNamespace}.phoneNumberVerified`,
      message: 'Phone number successfully verified and saved',
    },
  },
  PROCESSING_ADVICE: {
    key: `${namespace}.processingAdvice`,
    message:
      'Your file is currently being processed. This may take a moment. Please do not close this screen during this time.',
  },
  NO_BRANDS_FOUND: {
    TITLE: {
      key: `${namespace}.noBrandsFound`,
      message: 'No brands found',
    },
    DESCRIPTION: {
      key: `${namespace}.noBrandsFound`,
      message: 'Cannot find any brand for the current selected store',
    },
  },
};

export const {
  INTERNAL_ERROR,
  API_CONNECTION_ERROR,
  API_CONNECTION_REESTABLISHED,
  SOMETHING_WENT_WRONG_TRY_AGAIN,
  REMOVE_FILE_CONFIRMATION,
  CREATE_SALES_PERSON_ERROR,
  FAILED_TO_LOAD_DOCUMENT,
  FAILED_TO_LOAD_POSITIONS,
  CAMPAIGN_REDIRECTION_FAILED,
  PASSWORD_REDEFINE_SUCCESS,
  PASSWORD_REDEFINE_FAILED,
  BANK_ACCOUNT_SAVED_SUCCESS,
  FAILED_TO_SAVE_BANK_DATA,
  PROFILE_PICTURE_UPDATE_SUCCESS,
  UPDATE_PROFILE_PICTURE_FAILED,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERROR,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_FAILED,
  ACCOUNT_CREATION_ERROR,
  SMS_CODE_SENT,
  PHONE_NUMBER_VERIFICATION,
  INVALID_PHONE_NUMBER,
  PROCESSING_ADVICE,
  NO_BRANDS_FOUND,
} = i18n;

export default i18n;
