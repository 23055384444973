const NAMESPACE = 'user';
const I18N = {
  ID: {
    key: `${NAMESPACE}.id`,
    message: 'id',
  },
  USERID: {
    key: `${NAMESPACE}.userid`,
    message: 'user id',
  },
  FIRSTNAME: {
    key: `${NAMESPACE}.firstname`,
    message: 'firstname',
  },
  LASTNAME: {
    key: `${NAMESPACE}.lastname`,
    message: 'lastname',
  },
  EMAIL: {
    key: `${NAMESPACE}.email`,
    message: 'email',
  },
  PHONE: {
    key: `${NAMESPACE}.phone`,
    message: 'phone',
  },
  SALESMANID: {
    key: `${NAMESPACE}.salesmanId`,
    message: 'salesman id',
  },
  ROLES: {
    key: `${NAMESPACE}.roles`,
    message: 'roles',
  },
  ROLE: {
    key: `${NAMESPACE}.role`,
    message: 'Role',
  },
  PARTNER_GROUP: {
    key: `${NAMESPACE}.partnerGroup`,
    message: 'Partner Group',
  },
  GROUP: {
    key: `${NAMESPACE}.group`,
    message: 'Group',
  },
  PREFERRED_LANGUAGE: {
    key: `${NAMESPACE}.preferredLanguage`,
    message: 'Preferred Language',
  },
  MANAGEMENT: {
    LABEL: {
      key: `${NAMESPACE}.userManagement.label`,
      message: 'User Management',
    },
    INVITE: {
      key: `${NAMESPACE}.userManagement.inviteUser`,
      message: 'Invite New User',
    },
  },
};

export default I18N;
