import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import autoBind from 'auto-bind';
import createCache from 'keshi';

import { GetResourceBundlePath, GetRolesResourceBundlePath } from './apiPaths';
import logger from './log';
import { GetResourceBundleOptions, ResourceBundle } from './types';
import { mapToBrandCode } from './utils';

type Api = typeof defaultApiV2;

type ResourceServiceOptions = {
  apiv2?: Api;
};

class ResourceService {
  api: Api;

  cache: ReturnType<typeof createCache>;

  constructor({ apiv2 }: ResourceServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.cache = createCache();
    autoBind(this);
  }

  async getResourceBundle({
    bundleName,
    language,
    salesOrgBrand,
  }: GetResourceBundleOptions): Promise<ResourceBundle> {
    logger.debug(
      `Trying to get resource bundle for bundleName=${bundleName}, lang=${language}, sob=${salesOrgBrand}`,
    );
    try {
      const response: ApiResponse<ResourceBundle> = await this.api.get(
        GetResourceBundlePath({ bundleName }),
        {
          params: {
            language,
            brand: mapToBrandCode(salesOrgBrand),
          },
        },
      );
      logger.info(
        `Successfully got resource bundle for bundleName=${bundleName}`,
      );
      return response.data;
    } catch (error) {
      logger.error(
        `Error when getting resource bundle for bundleName=${bundleName}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async getCachedResourceBundle({
    bundleName,
    language,
    salesOrgBrand,
  }: GetResourceBundleOptions): Promise<ResourceBundle> {
    logger.debug(
      `Trying to get resource bundle for bundleName=${bundleName}, lang=${language}, sob=${salesOrgBrand}`,
    );
    const cacheKey = `resource-bundle:${bundleName}-${language}-${salesOrgBrand}`;
    return this.cache.resolve(
      cacheKey,
      async () =>
        this.getResourceBundle({ bundleName, language, salesOrgBrand }),
      '1m',
    );
  }

  async getAllRolesResources(
    language: string,
  ): Promise<Record<string, string>> {
    logger.debug('Will try to get all roles translations');
    try {
      const response: ApiResponse<ResourceBundle> = await this.api.get(
        GetRolesResourceBundlePath(language),
      );
      logger.info(`Successfully got roles resources`);
      return response.data;
    } catch (error) {
      logger.error(`Error when getting roles resources, error=%O`, error);
      throw error;
    }
  }
}

export default ResourceService;
