import { createDI } from '@di/core';
import api, { ApiResponse } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import logger from './log';
import paths from './paths';
import { ChangePasswordJSON, UserDetailsJSON } from './types';

export default class TraderProfileService {
  static createDI = createDI<TraderProfileService>;

  authenticationService: AuthenticationService;

  constructor({ authenticationService }: Partial<TraderProfileService> = {}) {
    if (authenticationService)
      this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getPersonalDetails() {
    logger.debug('Trying to get personal details');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UserDetailsJSON> = await api.get(
        paths.getPersonalDetails(),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got personal details');
      return data;
    } catch (error) {
      logger.error('Error getting personal details, error=%O', error);
      throw error;
    }
  }

  async setPersonalDetails(user: Partial<UserDetailsJSON>) {
    logger.debug('Trying to set personal details');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UserDetailsJSON> = await api.post(
        paths.getPersonalDetails(),
        user,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully set personal details');
      return data;
    } catch (error) {
      logger.error('Error setting personal details, error=%O', error);
      throw error;
    }
  }

  async changePassword(changePassword: ChangePasswordJSON) {
    logger.debug('Trying to set personal details');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      await api.post(paths.changePassword(), changePassword, {
        headers,
      });
      logger.info('Successfully set personal details');
    } catch (error) {
      logger.error('Error setting personal details, error=%O', error);
      throw error;
    }
  }
}
