import { defaultTo, get, isObject, isString, mergeWith, split } from 'lodash';
import { createContext, useContext, useMemo } from 'react';
import invariant from 'tiny-invariant';
import { U } from 'ts-toolbelt';

import DEFAULT_ICONS_SET from './defaultIconsMapping';
import {
  ContextValue,
  DefaultIconSet,
  IconSetProviderType,
  IconsSetProviderProps,
} from './interfaces';

const IconSetContext = createContext<ContextValue>(DEFAULT_ICONS_SET);

export const useIconSetContext = (): ContextValue => {
  const context = useContext(IconSetContext);
  invariant(
    context,
    'You must declare <IconSetProvider> before using this feature, no context found.',
  );
  return context;
};
export const useIcon = (name: string): U.Nullable<string> => {
  const iconsSet = useIconSetContext();
  const arrName = split(name, '-');
  const iconName = arrName[0];
  const level = arrName[1];
  const defaultValue =
    (isString(iconsSet[iconName]) && iconsSet[iconName]) ||
    get(iconsSet, `${iconName}.regular`) ||
    null;
  return useMemo(() => {
    if (!name) return null;
    const icon = level
      ? defaultTo(get(iconsSet, `${iconName}.${level}`), defaultValue)
      : defaultValue;
    if (!icon) {
      throw new Error(
        `The provided icon name "${name}" was not found in any icon set!`,
      );
    }
    return icon;
  }, [defaultValue, iconName, iconsSet, level, name]);
};

function useValue<CustomSet>(customSet: CustomSet): DefaultIconSet & CustomSet {
  return useMemo(
    () =>
      mergeWith(DEFAULT_ICONS_SET, customSet, (objValue, srcValue) => {
        if (isObject(objValue) && isString(srcValue)) {
          return {
            ...objValue,
            regular: srcValue,
          };
        }
        if (isObject(objValue) && isObject(srcValue)) {
          return {
            ...objValue,
            ...srcValue,
          };
        }
        if (isString(objValue) && isObject(srcValue)) {
          return {
            regular: objValue,
            ...srcValue,
          };
        }
        return srcValue || objValue;
      }),
    [customSet],
  );
}

function IconSetProvider<CustomIconSet>({
  children,
  customSet = {},
}: IconsSetProviderProps<CustomIconSet>): IconSetProviderType {
  const value = useValue<CustomIconSet | Record<string, any>>(customSet);
  return (
    <IconSetContext.Provider value={value}>{children}</IconSetContext.Provider>
  );
}

export default IconSetProvider;
