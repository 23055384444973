import config from '@mmw/config';
import { Config } from '@mmw/config/types';
import { CountryCode, GERMANY } from '@mmw/constants-country-codes';
import { DE, LanguageCode } from '@mmw/constants-languages';
import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import { getEnvironment } from '@mmw/environment';
import logger, {
  disable as logDisable,
  enable as logEnable,
  getNamespaces as logNamespaces,
  Log,
} from '@mmw/logging-logger';
import { SecurityScopeNames } from '@mmw/services-auth-api-authentication/types';
import { set } from '@mmw/utils-object-utils';
import { F, O, S, U } from 'ts-toolbelt';

type ScriptConfig = {
  name: string;
  username: string;
  password: string;
  executionsPath: string;
};

type WebApplicationConfig = {
  contextPath: string;
};

type ContextConfig = {
  application: {
    applicationId: string;
    displayName: string;
    salesOrgBrandID: SalesOrgBrand;
    supportedSalesOrgBrands?: U.Nullable<SalesOrgBrand[]>;
    supportedLanguages: LanguageCode[];
    defaultLanguage: LanguageCode;
    supportedCountries?: CountryCode[];
    defaultCountry?: CountryCode;
    defaultResourceBundleName: string;
    secondaryResourceBundleName?: string;
    logger: Log;
    checkAuthenticationTimeout: number;
    resources?: Record<
      string,
      U.Nullable<string> | Record<string, U.Nullable<string>>
    >;
    authScopeName?: SecurityScopeNames;
    version?: string;
    crossClientLoginAndLogoutEnabled?: boolean;
  };
  logs?: {
    applicationId: string;
  };
  webApplication: WebApplicationConfig | void;
  script: ScriptConfig | void;
  googleMaps: {
    apikey?: string;
    routeBase?: string;
  };
  googleTranslate?: {
    apikey?: string;
  };
  googleAnalytics?: {
    trackingID?: string;
  };
  googleTagManager?: {
    gtmId?: string;
  };
  piwik?: {
    siteId: number;
  };
};

export type ContextualConfig = ContextConfig & Config;

type GetConfigMethod = (
  environment: string,
  currentConfig: ContextualConfig,
) => Partial<ContextualConfig>;

const CONFIG_SINGLETON: ContextualConfig = {
  ...config, // starts with Config only
  application: {
    applicationId: 'trader-client',
    supportedLanguages: [DE],
    defaultLanguage: DE,
    defaultCountry: GERMANY,
    defaultResourceBundleName: 'TraderClient',
    salesOrgBrandID: -1,
    supportedSalesOrgBrands: null,
    displayName: 'AppName',
    secondaryResourceBundleName: undefined,
    logger,
    checkAuthenticationTimeout: 60 * 1000,
    resources: {},
    version: '',
    crossClientLoginAndLogoutEnabled: false,
  },
  webApplication: undefined,
  script: undefined,
  googleMaps: {},
};

export const setConfig = (getConfig: GetConfigMethod): void => {
  const oldNamespaces = logNamespaces();
  logEnable('*');
  logger.debug(`Configuring Contextual Config on ${getEnvironment()}`);
  logDisable();
  const configs = getConfig(getEnvironment(), CONFIG_SINGLETON);
  if (oldNamespaces != null) {
    logEnable(oldNamespaces);
  }
  Object.assign(CONFIG_SINGLETON, configs);
};

export const setConfigField = <P extends string>(
  path: F.AutoPath<ContextConfig, P>,
  value: O.Path<ContextConfig, S.Split<P, '.'>>,
): void => {
  // @ts-ignore
  set(CONFIG_SINGLETON, path, value);
};

export default CONFIG_SINGLETON;

export function getContextPath(): string {
  if (!CONFIG_SINGLETON.webApplication) {
    throw new Error('contextPath must be configured in webApplication');
  }
  const { contextPath } = CONFIG_SINGLETON.webApplication;
  return contextPath;
}

export function getLogger(): typeof CONFIG_SINGLETON.application.logger {
  return CONFIG_SINGLETON.application.logger;
}

export function getSalesOrgBrandID(): typeof CONFIG_SINGLETON.application.salesOrgBrandID {
  return CONFIG_SINGLETON.application.salesOrgBrandID;
}

export function getApplicationId(): typeof CONFIG_SINGLETON.application.applicationId {
  return CONFIG_SINGLETON.application.applicationId;
}

export function getRetailClientVersion() {
  return CONFIG_SINGLETON.clients.retailClient.version;
}

export function getClientVersion(): string {
  return CONFIG_SINGLETON.application.version || '';
}
