import { EMPTY_ARRAY } from '@mmw/constants-utils';
import contextualConfig from '@mmw/contextual-config';
import { Modifiers } from '@mmw/ui-theme/modifiers/types';
import parse from 'html-react-parser';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import uniqueId from 'lodash/uniqueId';
import without from 'lodash/without';
import React, { ComponentType, Fragment, ReactElement } from 'react';
import ReactHtmlParser, { processNodes, Transform } from 'react-html-parser';
import { U } from 'ts-toolbelt';

import tagsToModifier, { tableAttributesToProps } from './tagsMap';

const { logger } = contextualConfig.application;

const TABLE_TAGS = ['table', 'tr', 'td', 'th', 'thead', 'tbody'];
const MODIFIERS_TO_AVOID_IN_A = [
  'body1',
  'body2',
  'caption1',
  'caption2',
  'span',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
];
const generateModifiers = (
  modifiers: Modifiers,
  componentModifier: string,
): string[] =>
  isArray(modifiers)
    ? modifiers.concat(componentModifier)
    : [modifiers, componentModifier];

const HtmlParser = (
  html: string,
  Component: U.Nullable<ComponentType<unknown> | string>,
  modifiers: U.Nullable<Modifiers>,
  TypographyComponent: ComponentType<unknown>,
  allowHtmlStyle?: boolean,
): U.Nullable<ReactElement[]> => {
  try {
    if (!isString(html) || isEmpty(html)) {
      return null;
    }
    const RenderComponent = Component || TypographyComponent;
    const transform: Transform = node => {
      let children = null;
      const props = { ...node.attribs };
      if (props) {
        if (props.style && !includes(TABLE_TAGS, node.name) && !allowHtmlStyle)
          delete props.style;
        if (props.class && !allowHtmlStyle) delete props.class;
      }
      if (node.children) {
        children = processNodes(node.children, transform);
      }
      if (node.type === 'tag' && node.name === 'img') {
        return React.createElement('img', { ...node.attribs });
      }
      if (node.type === 'tag' && node.name === 'ul') {
        return React.createElement('ul', { key: uniqueId() }, children);
      }
      if (node.type === 'tag' && node.name === 'ol') {
        return React.createElement('ol', { key: uniqueId() }, children);
      }
      if (node.type === 'tag' && node.name === 'li') {
        return React.createElement('li', { key: uniqueId() }, children);
      }
      if (node.type === 'tag' && node.name === 'strong') {
        return React.createElement('strong', { key: uniqueId() }, children);
      }
      if (node.type === 'tag' && node.name === 'u') {
        return React.createElement('u', { key: uniqueId() }, children);
      }
      if (node.type === 'tag' && includes(TABLE_TAGS, node.name)) {
        tableAttributesToProps(props);
        return React.createElement(
          node.name,
          { key: uniqueId(), ...props },
          children,
        );
      }
      if (node.type === 'tag' && node.name === 'a') {
        const customModifiers = without(
          generateModifiers(modifiers || EMPTY_ARRAY, 'a'),
          ...MODIFIERS_TO_AVOID_IN_A,
        );
        const { onclick } = node.attribs;
        if (!isEmpty(onclick)) {
          const linkRegex = /'(.+)'/;
          const url = linkRegex.exec(onclick);
          return React.createElement(
            TypographyComponent,
            {
              key: uniqueId(),
              // @ts-ignore
              href: url[1],
              target: '_blank',
              modifiers: customModifiers,
            },
            children,
          );
        }
        return React.createElement(
          TypographyComponent,
          {
            key: uniqueId(),
            modifiers: customModifiers,
            ...props,
          },
          children,
        );
      }
      if (node.type === 'tag' && node.name !== 'br' && node.name !== 'a') {
        const componentModifier = tagsToModifier[node.name] || 'span';
        const customModifiers = generateModifiers(
          modifiers || EMPTY_ARRAY,
          componentModifier,
        );
        if (['html', 'body', 'head'].indexOf(node.name) > -1) {
          return React.createElement(Fragment, { key: uniqueId() }, children);
        }
        return React.createElement(
          RenderComponent,
          {
            key: uniqueId(),
            modifiers: customModifiers,
            ...props,
          },
          children,
        );
      }
      if (node.type === 'text') {
        const customModifiers = generateModifiers(
          modifiers || EMPTY_ARRAY,
          'span',
        );
        // const variant = tagsToModifierMap[node.parent.name];
        return React.createElement(
          RenderComponent,
          {
            key: uniqueId(),
            modifiers: customModifiers,
            // variant,
            ...props,
          },
          node.data,
        );
      }
      if (node.type === 'tag' && node.name === 'br') {
        return React.createElement('br', { key: uniqueId() });
      }
      return undefined;
    };
    const options = {
      transform,
    };
    // console.log('html=', html);
    if (html.includes('<a')) {
      return parse(
        `
        <h1>
          HTMLReactParser<br class="remove"> with Create React App (TypeScript)
        </h1>
      `,
      );
    }

    return ReactHtmlParser(html, options);
  } catch (error) {
    logger.error('Error during conversion of question html=', html, error);
    return null;
  }
};

export default HtmlParser;
