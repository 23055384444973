import { Avatar as MuiAvatar, Paper, PaperProps } from '@material-ui/core';
import { css } from '@ui-system/css';
import defaultProps from '@ui-system/default-props/avatar';
import { AvatarProps, AvatarType } from '@ui-system/interfaces-avatar';
import Spinner from '@ui-system/material-spinner';
import React, { useMemo } from 'react';

const STYLES = {
  small: css`
    height: 28px;
    width: 28px;
  `,
  medium: css`
    height: 35px;
    width: 35px;
  `,
  giant: css`
    height: 50px;
    width: 50px;
  `,
};

const Avatar: AvatarType = ({
  style,
  variant,
  src,
  size,
  loading,
  children,
  ...props
}: AvatarProps & Partial<PaperProps>) => {
  const finalStyle = useMemo(
    () => ({
      ...STYLES[size || 'medium'],
      ...style,
    }),
    [size, style],
  );
  if (loading) return <Spinner visible={loading} />;
  return (
    <MuiAvatar
      variant={variant}
      src={src}
      style={finalStyle}
      component={Paper}
      {...props}
    >
      {children}
    </MuiAvatar>
  );
};

Avatar.defaultProps = defaultProps;

export default Avatar;
