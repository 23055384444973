import { Pagination } from '@mmw/services-core-common/types';
import { TraderJSON } from '@mmw/services-core-store-locator/types';
import { F, U } from 'ts-toolbelt';

export enum TraderSearchSortOrder {
  COMPANY = 0,
  STREET = 1,
  ZIPCODE = 2,
  CITY = 3,
  DISTANCE = 4,
  BRAND_RANKING = 5,
  BRAND_RANKING_AND_CONTAINS_PRODUCT = 6,
  CONTAINS_PRODUCT = 7,
}

// TODO: missing more attrs
export interface SearchRequestObject {
  appName?: string;
  city?: string;
  company?: string;
  zipcode?: string;
  street?: string;
  campaign?: string;
  brands?: string;
  products?: string;
  productGroups?: string;
  country?: U.Nullable<string>;
  erpMaterialKeys?: string;
  demoDeviceErpMaterialKey?: string;
  productIDs?: string;
  classOfGoodsIDs?: string;
  orgunitGroup?: string;
  domain?: number;
  lat: number;
  lng: number;
  radius?: number;
  brand?: number;
  campaignID?: number;
  salesOrgBrand?: number;
  brandIDs?: string;
  productGroupIDs?: string;
  orgunitID?: number;
  similar?: boolean;
  forceFilterByCampaignOrgunits?: boolean;
  forceFilterByBusinessRelation?: boolean;
  returnsDistance?: boolean;
  sortOrder?: TraderSearchSortOrder;
  returnsDetails?: boolean;
  returnsErpnumber?: boolean;
  productsAvailableInStock?: boolean;
  productsInDemonstration?: boolean;
  returnsOnlyTradersRankedByBrand?: boolean;
  returnsOnlyDisposableTraders?: boolean;
  filterBySoundex?: boolean;
  returnsCampaignAgreementStatus?: boolean;
  returnsReadyDemoDevices?: boolean;
  returnsOnlyWithContractsNotExpired?: boolean;
  returnsOnlyWithProjectsOnTheLast6Months?: boolean;
  campaignCodes?: string;
  orgunitGroups?: string;
  language: string;
  offset?: number;
  limit?: number;
}

export type Coordinates = {
  lat: number;
  lng: number;
};

export type ContainerRquestParamsType = Omit<
  SearchRequestObject,
  'language' | 'lat' | 'lng' | 'radius' | 'brand' | 'salesOrgBrand'
>;

export type StoresLocationSearchResult = Pagination<TraderJSON>;

export interface StoreLocatorState {
  lastCoordinates: U.Nullable<Coordinates>;
  lastRadius: number;
  lastRequestObject: U.Nullable<Partial<SearchRequestObject>>;
  storeIdsList: U.Nullable<number[]>;
  itemsById: Record<number, TraderJSON>;
  selectedStore: U.Nullable<number>;
  total: U.Nullable<number>;
  searchStores: F.Function<[Partial<SearchRequestObject>]>;
  resetSearch: F.Function;
  loading: boolean;
  error: U.Nullable<Error>;
  alreadySearch: boolean;
  lastSearchByUserLocation: boolean;
}
