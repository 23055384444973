import qs from 'qs';

import { GetResourceBundlePathOptions } from './types';

export const GetResourceBundlePath = ({
  bundleName,
}: GetResourceBundlePathOptions): string => `/v1/resource/bundle/${bundleName}`;

export const GetRolesResourceBundlePath = (language: string): string =>
  `/v1/resource/bundle/Roles?${qs.stringify({ language })}`;
