// import { dateByCountry } from '@dates/core';
// import { Formats } from '@dates/core/formats';
// import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import { useTranslate, useTranslateShape } from '@mmw/utils-text-utils/hooks';
import { U } from '@utils/ts';
import { format } from 'date-fns';
import { FormatDateOptions, useIntl } from 'react-intl';

export type { I18nShape } from '@mmw/constants-i18n';
export { deepmerge, EMPTY_OBJECT } from '@shared-utils/object';
// ts-toolbelt, lodash, immutability-helper, @ui-kitten/components, @eva-design/eva
// react, react-native-snap-carousel, react-native, nanoid, @utils/ts, emotion-theming, @emotion/native, @emotion/serialize
// style-to-js, @hasher/object-hash, color , @material-ui/core, react-native-expo-image-cache
// react-native-svg, expo-device
// react-native-iphone-x-helper
export { fastHash } from '@hasher/object-hash';

export const useTranslatedMessage = useTranslateShape;
export const useTranslateFunction = useTranslate;

export function useFormatedDate(
  date: U.Nullable<Date | number | string>,
  dateFormat?: FormatDateOptions,
): U.Nullable<string> {
  // const language = useLanguage();
  // return dateByCountry(date, language as Formats);
  const intl = useIntl();
  if (!date) return null;
  return intl.formatDate(format(date), dateFormat);
}
