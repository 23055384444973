import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import useOnKeyDown from '@mmw/ui-web-hooks-use-input-key-down';
import { useDeepCompareMemo } from '@react-utils/hooks';
import { isHexColor } from '@ui-system/color';
import { applyColor, Color, css } from '@ui-system/css';
import componentModifiers from '@ui-system/default-modifiers/form/input';
import defaultProps from '@ui-system/default-props/form/input';
import {
  useInputContainerStyle,
  useInputStyle,
} from '@ui-system/default-styles';
import { useTranslateFunction } from '@ui-system/deps';
import { InputProps } from '@ui-system/interfaces-form/input';
import { useTheme } from '@ui-system/theme';
import UI from '@ui-system/ui';
import pick from 'lodash/pick';
import React, { useMemo } from 'react';
import Masked from 'react-text-mask';

export const Input: React.FC<InputProps> = ({
  value,
  mask,
  error,
  label,
  labelColor,
  labelComponent,
  labelModifiers,
  labelVariant,
  onChange,
  accessoryLeft,
  accessoryRight,
  placeholder,
  caption,
  textAlign,
  style,
  modifiers,
  multiline,
  inputStyle,
  onSubmitEditing,
  disabled,
  InputLabelProps,
  innerRef,
  required,
  ...props
}: InputProps) => {
  const finalStyle = useInputContainerStyle(
    style,
    error ? `${modifiers}, error` : modifiers,
    componentModifiers,
    {
      disabled,
    },
  );

  const inputFinalStyle = useInputStyle(
    inputStyle,
    error ? `${modifiers}, error` : modifiers,
    componentModifiers,
    {
      disabled,
    },
  );

  const theme = useTheme();
  const MuiLabelColor = isHexColor(labelColor as string)
    ? labelColor
    : applyColor(theme, labelColor as Color, true);

  const MuiLabelInputProps = useDeepCompareMemo(
    () => ({
      ...InputLabelProps,
      style: css`
        position: unset;
        color: ${MuiLabelColor};
        margin-left: 5px;
        margin-bottom: 5px;
      `,
    }),
    [InputLabelProps, MuiLabelColor],
  );
  const MuiInputProps = useDeepCompareMemo(
    () => ({
      startAdornment: (
        <InputAdornment position="start">{accessoryLeft || ''}</InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">{accessoryRight || ''}</InputAdornment>
      ),
      disableUnderline: true,
      style: inputFinalStyle,
      inputComponent: mask
        ? rest => <Masked mask={mask} guide showMask {...rest} />
        : undefined,
    }),
    [accessoryLeft, accessoryRight, inputFinalStyle, textAlign, mask],
  );
  /**
   * XXX: This is a workaround because of material ui props differ between InputProps and inputProps
   * to customize style in this props, add style properties to pick funciton carefully to not broke
   * other fields
   */
  const inputProps = useDeepCompareMemo(
    () => ({
      ...props,
      disabled,
      required,
      type: props.secureTextEntry ? 'password' : props.type,
      style: {
        textAlign,
        ...pick(inputFinalStyle, ['textTransform', 'fontSize', 'height']),
      },
    }),
    [props, disabled, textAlign],
  );
  const translate = useTranslateFunction();
  const texts = useMemo(() => {
    const labelText = translate(label);
    return {
      placeholder: translate(placeholder),
      label: inputProps?.required ? `${labelText || ''} * ` : labelText,
      caption: translate(caption),
    };
  }, [caption, inputProps?.required, label, placeholder, translate]);
  const onKeyDown = useOnKeyDown();
  return (
    <TextField
      ref={innerRef}
      fullWidth
      error={error}
      value={value as string}
      label={
        labelComponent || texts.label ? (
          <UI.Body1
            modifiers={`grayA400, ${labelModifiers || 'uppercase'}, ${error || texts.caption ? 'error' : ''}`}
            style={css`
              line-height: 16px;
              height: 16px;
              margin-left: 5px;
            `}
            variant={labelVariant}
          >
            {texts.label}
          </UI.Body1>
        ) : null
      }
      InputLabelProps={MuiLabelInputProps}
      placeholder={texts.placeholder}
      helperText={error && texts.caption}
      InputProps={MuiInputProps as Record<string, any>}
      // @ts-ignore
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={inputProps}
      style={finalStyle as Record<string, any>}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
        onKeyDown(e, onSubmitEditing)
      }
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        onChange(e.target.value);
      }}
      onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
        onChange((e.target as HTMLInputElement).value);
      }}
      multiline={multiline}
      // removed prop
      // {...inputProps}
      // required={required}
    />
  );
};

Input.defaultProps = defaultProps;
export default Input;
