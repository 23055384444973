// eslint-disable-next-line max-classes-per-file
import { Roles } from '@mmw/constants-roles';
import { REC } from '@mmw/constants-salesorgbrand-ids';
import {
  AddressJSON,
  CampaignJSON,
  UserJSON,
} from '@mmw/services-core-common/types';
import { OrgunitDetailsJSON } from '@mmw/services-core-store-locator/types';
import { getFieldPaths } from '@shared-utils/object';
import { O, U } from '@utils/ts';
import dayjs from 'dayjs';
import { find } from 'lodash';

export class OpeningTimesJSON {
  static dayweek(dayweek: number /* 0-6 */): Date {
    // from May/2022, or any sunday as first month day
    return new Date(`5/${dayweek + 1}/22`);
  }

  static period(open: Date, close: Date): OpeningTimesJSON {
    return new OpeningTimesJSON({
      id: null,
      dayOfWeek: open.getDay(),
      openingHour: open.getHours(),
      openingMinutes: open.getMinutes(),
      closingHour: close.getHours(),
      closingMinutes: close.getMinutes(),
    });
  }

  static date(times: OpeningTimesJSON): [Date, Date] {
    const opened = OpeningTimesJSON.dayweek(times.dayOfWeek);
    opened.setHours(times.openingHour);
    opened.setMinutes(times.openingMinutes);
    const closed = OpeningTimesJSON.dayweek(times.dayOfWeek);
    closed.setHours(times.closingHour);
    closed.setMinutes(times.closingMinutes);
    return [opened, closed];
  }

  id: number | null = null;

  dayOfWeek: number | null = null;

  openingHour: number | null = null;

  openingMinutes: number | null = null;

  closingHour: number | null = null;

  closingMinutes: number | null = null;

  // FRONT END ID
  temporaryId?: number | null = null;

  constructor(openingTimes: OpeningTimesJSON = {} as OpeningTimesJSON) {
    return {
      ...this,
      ...openingTimes,
    };
  }
}

export enum OrgUnitSortOrder {
  COMPANY,
  STREET,
  ZIPCODE,
  CITY,
  DISTANCE,
  NEXT_AUDIT_DATE,
  ID_DESC,
  ERP_NUMBER_ASC,
  ERP_NUMBER_DESC,
  CONTRACT_AGREEMENT_EXPIRATION_DATE_ASC, // contractAgreement.expirationDate on response JSON
  CONTRACT_AGREEMENT_EXPIRATION_DATE_DESC,
  CONTRACT_AGREEMENT_CREATION_DATE_ASC, // contractAgreement.creationDate on response JSON
  CONTRACT_AGREEMENT_CREATION_DATE_DESC,
  ERP_USAGE_ASC, // erpusage (string[]) on request json and erpServices[0].erpusage on response JSON
  ERP_USAGE_DESC,
  LAST_LOGIN_ASC,
  LAST_LOGIN_DESC,
}

export enum OrgunitDetailsType {
  INFO,
  SALES,
  SUPPORT,
}

export class OrgUnitDetailsJSON {
  id: number | null = null;

  companyId: number | null = null;

  type: U.Nullable<OrgunitDetailsType> = null;

  fax = '';

  phone = '';

  email = '';

  homepage = '';

  address: U.Nullable<AddressJSON> = null;

  categories: U.Nullable<string[]> = null;

  services: U.Nullable<string[]> = null;

  information: U.Nullable<string> = null;

  lastAuditDate: U.Nullable<Date> = null;

  nextAuditDate: U.Nullable<Date> = null;

  openingTimes: OpeningTimesJSON[] = [];

  constructor(details: U.Nullable<OrgUnitDetailsJSON | O.Object> = {}) {
    return {
      ...this,
      ...details,
    };
  }
}

export class OrgUnitAddressJSON {
  addressID: number | null = null;

  apiVerified = false;

  city = '';

  company = '';

  country = '';

  lat: number | null = null;

  lng: number | null = null;

  name1 = '';

  name2 = '';

  nr = '';

  state = '';

  street = '';

  zipcode = '';

  constructor(address = {} as Partial<OrgUnitAddressJSON>) {
    return {
      ...this,
      ...address,
    };
  }
}

export enum ErpUsage {
  '1P' = '1P',
  '2T' = '2T',
  '2SP' = '2SP',
  AD = 'AD',
  PP = 'PP',
  RTIC = 'RTIC',
  RTIP = 'RTIP',
  RCSP = 'RCSP',
  RCSP_PREMIER = 'RCSP-PREMIER',
  RCSP_ENTERPRISE = 'RCSP-ENTERPRISE',
  RCSP_BRONZE = 'RCSP-BRONZE',
  RCSP_GOLD = 'RCSP-GOLD',
  RCSP_SILVER = 'RCSP-SILVER',
  RCSP_PLATINUM = 'RCSP-PLATINUM',
}
export const ERP_USAGE_RANKING_MAP = {
  AD: 'AAA',
  PP: 'AAA',
  RTIC: 'AAB',
  RTIP: 'AAA',
  RCSP: 'BBB',
  RCSP_PREMIER: 'AAB',
  RCSP_ENTERPRISE: 'AAA',
  RCSP_BRONZE: 'BBB',
  RCSP_GOLD: 'AAB',
  RCSP_SILVER: 'ABB',
  RCSP_PLATINUM: 'AAA',
};

export const ERP_USAGE_REC_RTI = [ErpUsage.RTIC, ErpUsage.RTIP];
export const ERP_USAGE_REC_RCSP = [
  ErpUsage.RCSP,
  ErpUsage.RCSP_PREMIER,
  ErpUsage.RCSP_ENTERPRISE,
  ErpUsage.RCSP_BRONZE,
  ErpUsage.RCSP_GOLD,
  ErpUsage.RCSP_SILVER,
  ErpUsage.RCSP_PLATINUM,
];

type DateGetter = (params: { recertificationDate?: string | Date }) => Date;

function defaultRCSPDateGetter({
  recertificationDate,
}: Parameters<DateGetter>[0]) {
  const date =
    recertificationDate && dayjs(recertificationDate).isValid()
      ? dayjs(recertificationDate)
      : dayjs();
  return date.add(2, 'years').toDate();
}

function defaultRTIsAndDistributorsDateGetter({
  recertificationDate,
}: Parameters<DateGetter>[0]) {
  const date =
    recertificationDate && dayjs(recertificationDate).isValid()
      ? dayjs(recertificationDate)
      : dayjs();
  return date.endOf('year').subtract(1, 'day').toDate();
}
export const ERP_USAGE_GET_CERTIFICATE_VALIDATION_MAP: Record<
  ErpUsage,
  DateGetter
> = {
  [ErpUsage.AD]: defaultRTIsAndDistributorsDateGetter,
  [ErpUsage.PP]: defaultRTIsAndDistributorsDateGetter,
  [ErpUsage.RTIC]: defaultRTIsAndDistributorsDateGetter,
  [ErpUsage.RTIP]: defaultRTIsAndDistributorsDateGetter,
  [ErpUsage.RCSP]: defaultRCSPDateGetter,
  [ErpUsage.RCSP_PREMIER]: defaultRCSPDateGetter,
  [ErpUsage.RCSP_ENTERPRISE]: defaultRCSPDateGetter,
  [ErpUsage.RCSP_BRONZE]: defaultRCSPDateGetter,
  [ErpUsage.RCSP_GOLD]: defaultRCSPDateGetter,
  [ErpUsage.RCSP_SILVER]: defaultRCSPDateGetter,
  [ErpUsage.RCSP_PLATINUM]: defaultRCSPDateGetter,
  //
  [ErpUsage['1P']]: defaultRCSPDateGetter,
  [ErpUsage['2SP']]: defaultRCSPDateGetter,
  [ErpUsage['2T']]: defaultRCSPDateGetter,
};

export const DISTRIBUTORS_ERP_USAGES: ErpUsage[] = [
  ErpUsage.PP,
  ErpUsage.AD,
  ErpUsage['1P'],
  ErpUsage['1G'],
];

export function mapErpUsageToDefaultNewUserRole(
  salesOrgBrandID: number,
  erpusage?: string,
): Roles {
  const isDist = find(DISTRIBUTORS_ERP_USAGES, (d: ErpUsage) => d === erpusage);

  if (isDist) {
    return Roles.SOLARDIST;
  }

  if (REC === salesOrgBrandID) {
    return Roles.SOLARUSER;
  }
  return Roles.USER;
}

export type ErpServiceJSON = {
  businessRelationID: number;
  erpnumber: string;
  erpusage: ErpUsage | string;
  rewardType: U.Nullable<unknown>;
  service: number;
  salesOrgBrandID: number;
};

export interface OrgUnitContractAgreementJSON {
  id: number;
  creationDate: Date | null;
  acceptanceDate: Date | null;
  confirmationDate: Date | null;
  approvalDate: Date | null;
  cancellationDate: Date | null;
  renewalDate: Date | null;
  expirationDate: Date | null;
  agreementCode: string;
  contractStatus: number;
  acceptedBy: number;
  confirmedBy: number;
  approvedBy: number;
  cancelledBy: null; // ???
  authorisedSignatory: null; // ???
  acceptedByUserInfo: {
    firstname: string;
    lastname: string;
  };
  agreement: {
    id: number;
    name: string;
    orgunitId: number;
    type: number;
    configurationId: number;
    acontract: boolean;
    campaignId: null; // ???
    typeLabel: null; // ???
    questionGroupType: null; // ???
    agreementConfigurationKeys: null; // ???
  };
  orgunitID: number;
}

export interface InstalledSystemSize {
  total: number;
  [year: string]: number;
}

export class OrgUnitJSON {
  id: number | null = null;

  companyId: number | null = null;

  address: OrgUnitAddressJSON = new OrgUnitAddressJSON();

  mmwAddress: OrgUnitAddressJSON = new OrgUnitAddressJSON();

  brAddress: OrgUnitAddressJSON = new OrgUnitAddressJSON();

  erpnumber: U.Nullable<string>;

  details: OrgUnitDetailsJSON[] = [new OrgUnitDetailsJSON()];

  campaigns: CampaignJSON[] = [];

  accountCreditAuthenticated: U.Nullable<unknown> = null;

  accountDebitAuthenticated: U.Nullable<unknown> = null;

  lastLogin: U.Nullable<string> = null;

  erpList: string[] = [];

  hasAccountCreditErrors = false;

  hasAccountDebitErrors = false;

  erpServices: ErpServiceJSON[] = [];

  users: UserJSON[] = [];

  groups: string[] | null = null;

  storeLocator: OrgunitDetailsJSON;

  email = '';

  fax = '';

  phone = '';

  homepage = '';

  taxnumber = '';

  gln = '';

  salestaxid = '';

  displayname = '';

  image: U.Nullable<string>;

  legalform = '';

  managementBody = '';

  crcity = '';

  crnumber = '';

  legalEntityID: number;

  contractAgreement: OrgUnitContractAgreementJSON;

  installedSystemSize?: InstalledSystemSize;

  agreements: OrgUnitContractAgreementJSON[];

  constructor(orgunit: U.Nullable<Partial<OrgUnitJSON>> = {} as OrgUnitJSON) {
    return {
      ...this,
      ...orgunit,
    };
  }
}

export interface OrgUnitDataJSON {
  id: number;
  participant: number;
  owner: string;
  phone: string;
  crcity: string;
  crnumber: string;
  email: string;
  homepage: string;
  displayname: string;
  gln: string;
  taxnumber: string;
  salestaxid: string;
  erpnumber: string;
  legalform: string;
  updated: Date;
  mmwAddress: OrgUnitAddressJSON;
  brAddress: OrgUnitAddressJSON;
}

export type AcceptorRoles = O.Object;

export type SalesOrgBrandIDs = O.Object;

export enum BRActivationFilter {
  DEFAULT = 'DEFAULT',
  ONLY_ACTIVES = 'ONLY_ACTIVES',
  ONLY_NOT_ACTIVES = 'ONLY_NOT_ACTIVES',
  ACTIVES_AND_NOT_ACTIVES = 'ACTIVES_AND_NOT_ACTIVES',
}

export enum SIZE {
  LESS_OR_EQUAL = 'LESS_OR_EQUAL',
  GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
}

export class ProjectSystemSize {
  systemSizeKwp?: U.Nullable<number>;

  year?: U.Nullable<number>;

  queryMode?: U.Nullable<SIZE>;
}

export const PROJECT_SYSTEM_SIZE_PATHS = getFieldPaths(new ProjectSystemSize());

export class OrgUnitRequest {
  legalEntityID?: number;

  offset?: number = 0;

  limit?: number = 10;

  ids?: number[];

  acceptorRole?: string;

  acceptorRoles?: AcceptorRoles;

  addressType?: string;

  brandID?: number;

  businessRelationOfferorID?: number;

  campaignCode?: string;

  campaignID?: number;

  city?: string;

  company?: string;

  likeCompany?: string;

  countries?: string[];

  country?: string;

  displayname?: string;

  domain?: number;

  domkeyAddressTyp?: string;

  email?: string;

  erpnumber?: string;

  erpusage?: string[];

  filterByBrandCampaignOrgunits?: boolean;

  gln?: string;

  ignoreDisposableTraders?: boolean;

  ignoreErpNumberLeadingZero?: boolean;

  ignoreService?: boolean;

  ignoreWithoutAddress?: boolean;

  latitude?: number;

  levenshtein?: boolean;

  levenshteinDistance?: number;

  likeCampaignCode?: string;

  longitude?: number;

  managedByUserid?: string;

  manufacturerID?: number;

  offerorRole?: string;

  orgunitType?: string;

  participant?: number;

  radius?: number;

  returnOnlyWithAdvContractDone?: boolean;

  returnOnlyWithAdvContractPending?: boolean;

  returnOnlyWithAnyAdvContract?: boolean;

  returnsDistance?: boolean;

  returnsOnlyDisposableTraders?: boolean;

  salesOrgBrandID?: number;

  salesOrgBrandIDs?: SalesOrgBrandIDs;

  salestaxid?: string;

  taxnumber?: string;

  showManualByConsumer?: boolean;

  showManualByMMW?: boolean;

  showmanual?: boolean;

  sortOrder?: OrgUnitSortOrder;

  state?: string;

  street?: string;

  userEmail?: string;

  userid?: string;

  zipcode?: string;

  returnOnlyLegalEntities?: boolean;

  minimumLoginDate?: string;

  brActivationFilter?: BRActivationFilter = BRActivationFilter.DEFAULT;

  maximumContractExpirationDate?: string;

  projectSystemSize?: U.Nullable<ProjectSystemSize>;

  agreementContract?: U.Nullable<AgreementContractRequestObject> = null;
}

export class AgreementContractRequestObject {
  name: string;

  onlyAccepted: boolean | null;

  onlyNotAccepted: boolean | null;

  constructor(params: Partial<AgreementContractRequestObject>) {
    this.name = params.name || '';
    this.onlyAccepted = params.onlyAccepted || false;
    this.onlyNotAccepted = params.onlyNotAccepted || false;
  }
}

export const ORGUNIT_REQUEST_FIELD_PATHS = getFieldPaths(new OrgUnitRequest());

export interface OrgUnitRequestQuerystring {
  returnBusinessRelation?: boolean;
  returnCampaigns?: boolean;
  returnUsers?: boolean;
  returnDetails?: boolean;
  returnSystemSizes?: boolean;
  returnOrgunitGroups?: boolean;
  returnAgreements?: boolean;
}

export type OperationResultJSON = {
  success: boolean;
};

export class EmailConfigurationJSON {
  subjectResourcekey: string;

  bodyResourcekey: string;

  bundleName: string;

  language: string;

  salesOrgBrandID?: number;

  senderEmail?: string;

  senderName?: string;
}

export class SendUserEmailRequestJSON {
  forceChangePassword: boolean;

  singleSignOn: boolean;

  emailConfiguration: EmailConfigurationJSON;

  applicationCallbackUrl: string;

  applicationId: string;

  tokenExpirationTimeMs?: number;

  overrideEmail?: string;

  bccRecipients?: string[];
}

export type UpdateOrgUnitDetailsResponse = Promise<OrgUnitDetailsJSON>;

export type UpdateOrgUnitAddressResponse = Promise<OrgUnitAddressJSON>;

export type RenewalAgreementContractReq = {
  orgunitID: number;
} & OrgUnitContractAgreementJSON;
