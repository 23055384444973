const namespace = 'oneSnap';

const ONE_SNAP = {
  PARTIAL_FAILED_MESSAGE: {
    key: `${namespace}.partialFailedMessage`,
    message:
      'Some fields in your document could not be read by the OneSnap AI. You can fill the registration form manually by clicking in the button below or try again with another invoice picture by clicking on one snap card on the left',
  },
  FAILED_MESSAGE: {
    key: `${namespace}.failedMessage`,
    message:
      'None of the registraiton fields could be recognized by the AI, this could be due to the invoice picture quality. You can fill the registration form manually by clicking in the button below or try again with another invoice picture by clicking in Take another invoice picture button',
  },
};

export default ONE_SNAP;
