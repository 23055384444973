import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { currentSalesOrgBrandSelector } from '@mmw/redux-store-current-salesorgbrand/stateSelector';
import { getResourceService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  pluck,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { U } from 'ts-toolbelt';

import {
  changeLanguageErrorAction,
  changeLanguageSuccessAction,
} from './actions';
import {
  bundleNameFieldPath,
  RootState,
  secondaryBundleNameFieldPath,
} from './stateSelector';
import {
  CHANGE_LANGUAGE_START,
  ChangeLanguageErrorAction,
  ChangeLanguageStartAction,
  ChangeLanguageSuccessAction,
} from './types';
import { languageStorage, USER_LANGUAGE_LOCALSTORAGE_KEY } from './utils';

const getResourcesAndLanguage = async (
  language: string,
  bundleName: string,
  salesOrgBrand?: U.Nullable<number>,
  secondaryBundleName?: U.Nullable<string>,
) => {
  const resourcesPromise = getResourceService().getCachedResourceBundle({
    bundleName,
    language,
    salesOrgBrand,
  });
  const promises = [resourcesPromise];
  if (secondaryBundleName) {
    const secondaryResourcesPromise =
      getResourceService().getCachedResourceBundle({
        bundleName: secondaryBundleName,
        language,
        salesOrgBrand,
      });
    promises.push(secondaryResourcesPromise);
  }
  const [resources, secondaryResources] = await Promise.all(promises);

  if (secondaryResources) {
    return {
      ...secondaryResources,
      ...resources,
    };
  }
  return {
    ...resources,
  };
};

const { logger } = contextualConfig.application;

type Input = ChangeLanguageStartAction;
type Output = ChangeLanguageSuccessAction | ChangeLanguageErrorAction;

const changeLanguageEpic = (
  action$: ActionsObservable<Input>,
  state$: Observable<RootState>,
): Observable<Output | Output> =>
  action$.pipe(
    ofType(CHANGE_LANGUAGE_START),
    withLatestFrom(
      state$.pipe(pluck(...bundleNameFieldPath)),
      state$.pipe(pluck(...secondaryBundleNameFieldPath)),
      state$.pipe(map(currentSalesOrgBrandSelector)),
    ),
    tap(() => logger.debug('Will load the resources')),
    switchMap(
      ([action, bundleName, secondaryBundleName, salesOrgBrand]: [
        ChangeLanguageStartAction,
        string,
        U.Nullable<string>,
        U.Nullable<number>,
      ]) =>
        from(
          getResourcesAndLanguage(
            action.payload.language,
            bundleName,
            salesOrgBrand,
            secondaryBundleName,
          ),
        ).pipe(
          map(data => {
            languageStorage.set(
              USER_LANGUAGE_LOCALSTORAGE_KEY,
              action.payload.language,
            );
            return changeLanguageSuccessAction(data, action.payload.language);
          }),
          catchError(error => of(changeLanguageErrorAction(error))),
        ),
    ),
  );

export default changeLanguageEpic;
