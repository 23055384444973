import { Color } from '@ui-system/css';
import defaultProps from '@ui-system/default-props/skeleton';
import { SkeletonProps, SkeletonType } from '@ui-system/interfaces-skeleton';
import { useHex } from '@ui-system/theme/colors';
import React from 'react';
import SkeletonLib, { SkeletonTheme } from 'react-loading-skeleton';

const Skeleton: SkeletonType = ({
  color,
  highlightColor,
  ...props
}: SkeletonProps) => {
  const baseColor = useHex(color as Color);
  const effectColor = useHex(highlightColor as Color);
  return (
    <SkeletonTheme color={baseColor} highlightColor={effectColor}>
      <SkeletonLib {...props} />
    </SkeletonTheme>
  );
};

Skeleton.defaultProps = defaultProps;

export default Skeleton;
