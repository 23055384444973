import { currentSalesOrgBrandSelector } from '@mmw/redux-store-current-salesorgbrand/stateSelector';
import { from } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  pluck,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { U } from 'ts-toolbelt';

import { changeLanguageStartAction } from './actions';
import { languageFieldPath } from './stateSelector';
import { languageStorage, USER_LANGUAGE_LOCALSTORAGE_KEY } from './utils';

async function getSelectedLanguageFromLocalStorage(): Promise<
  U.Nullable<string>
> {
  const language: U.Nullable<string> = await languageStorage.get(
    USER_LANGUAGE_LOCALSTORAGE_KEY,
  );
  return language;
}

const onLocalSalesOrgBrandChangedEpic = (action$, state$) =>
  state$.pipe(
    map(currentSalesOrgBrandSelector),
    distinctUntilChanged(),
    withLatestFrom(state$.pipe(pluck(...languageFieldPath))),
    switchMap(([, language]: [any, string]) =>
      from(getSelectedLanguageFromLocalStorage()).pipe(
        map(lang => changeLanguageStartAction(lang || language)),
      ),
    ),
  );
export default onLocalSalesOrgBrandChangedEpic;
