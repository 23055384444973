import { ResourceBundle } from '@mmw/services-core-resource/types';

import {
  CHANGE_LANGUAGE_ERROR,
  CHANGE_LANGUAGE_START,
  CHANGE_LANGUAGE_SUCCESS,
  ChangeLanguageErrorAction,
  ChangeLanguageStartAction,
  ChangeLanguageSuccessAction,
} from './types';

export const changeLanguageStartAction = (
  language: string,
): ChangeLanguageStartAction => ({
  type: CHANGE_LANGUAGE_START,

  payload: {
    language,
  },
});

type ChangeLanguageSuccess = (
  resourceBundle: ResourceBundle,
  language: string,
) => ChangeLanguageSuccessAction;

export const changeLanguageSuccessAction: ChangeLanguageSuccess = (
  resourceBundle,
  language,
) => ({
  type: CHANGE_LANGUAGE_SUCCESS,
  payload: {
    resourceBundle,
    language,
  },
});
export const changeLanguageErrorAction = (
  error: Error,
): ChangeLanguageErrorAction => ({
  type: CHANGE_LANGUAGE_ERROR,

  payload: {
    error,
  },
});
