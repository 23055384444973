import MuiModal from '@material-ui/core/Modal';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { css } from '@ui-system/css';
import { ModalProps, ModalType } from '@ui-system/interfaces-modal';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

const SIZE = 30;
const PADDING = 5;
const STYLE = css`
  padding: ${PADDING}px;
  width: ${SIZE}px;
  height: ${SIZE}px;
  border-radius: 50px;
  position: relative;
  margin-left: auto;
  top: ${SIZE + 10}px;
  right: 45px;
  margin-right: 10px;
`;
const Children = React.forwardRef(
  (
    {
      children,
      toggle,
      hideCloseButton,
      style = EMPTY_OBJECT,
    }: React.PropsWithChildren<{
      toggle?: F.Function;
      children: React.ReactNode;
      hideCloseButton?: boolean;
      style?: Record<string, any>;
    }>,
    ref,
  ) => (
    <>
      {!hideCloseButton && (
        <UI.Container
          align="center"
          justify="center"
          style={STYLE}
          onClick={toggle}
        >
          <UI.Icon name="close-circle" size={SIZE} color="primary" />
        </UI.Container>
      )}
      {React.cloneElement(children as React.ReactElement<any, any>, {
        ref,
        style: { zIndex: 999999, ...style },
      })}
    </>
  ),
);

const Modal: ModalType = ({
  style,
  contentStyle,
  children,
  visible,
  toggle,
  hideCloseButton,
}: ModalProps): React.ReactElement => (
  <>
    <MuiModal style={style} open={visible} onClose={toggle}>
      <Children
        style={contentStyle}
        toggle={toggle}
        hideCloseButton={hideCloseButton}
      >
        {children}
      </Children>
    </MuiModal>
  </>
);
export default Modal;
