import config, { getDefaultApiHostUrl } from '@mmw/config';
import { AUTHENTICATION_EMAIL_APPLICATION_BASE_URL } from '@mmw/constants-application-paths';
import { RECOVER_PASSWORD_BUNDLE_TOKEN } from '@mmw/constants-emails';
import { LanguageCode } from '@mmw/constants-languages';
import contextualConfig from '@mmw/contextual-config';
import isReactNative from '@mmw/utils-platform';
import qs from 'qs';
import { Container } from 'typedi';

import { BaseEmailConfiguration, RecoverPasswordBaseParams } from './types';

const BASE_PATH = '/authentication';
const TAN_BASE_PATH = '/tan';
const STATUS_BASE_PATH = '/status';
const SSO_BASE_PATH = '/sso';

const NOT_AUTHENTICATED_OPERATION_PATH = '/not-authenticated-operation';

export const RETAIL_CLIENT_URL = config?.links?.common?.retailClient?.v3;
export const RETAIL_CLIENT_REFERER_HEADERS = isReactNative
  ? { headers: { Referer: RETAIL_CLIENT_URL } }
  : { headers: {} };

export const AuthenticatePath = (): string => `${BASE_PATH}`;
export const RefreshAuthenticationPath = (): string => `${BASE_PATH}/refresh`;
export const LogoutPath = (): string => `${BASE_PATH}/logout`;
export const GoogleTokenAuthPath = (): string =>
  `${BASE_PATH}/authenticate-with-google-token`;
export const RequestUnblockPath = (): string => '/request-unblock';
// TAN
export const RequestTanPath = (): string => `${TAN_BASE_PATH}/request`;
export const AuthenticateByTanPath = (): string =>
  `${TAN_BASE_PATH}/authentication`;
// STATUS
export const GetUseridByEmailPath = (): string =>
  `${STATUS_BASE_PATH}/retrieve-userid`;
export const ValidateSystemTokenPath = (): string =>
  `${STATUS_BASE_PATH}/validate-system-token`;
// SSO
export const GenerateSSOTokenBySystemTokenPath = (): string =>
  `${SSO_BASE_PATH}/generate-token-by-system-token`;
export const GenerateSSOTokenByRefreshTokenPath = (): string =>
  `${SSO_BASE_PATH}/generate-token-by-refresh-token`;
export const SendAuthenticationEmailPath = (): string =>
  `${SSO_BASE_PATH}/send-authentication-email`;
export const AuthenticateBySSOTokenPath = (): string =>
  `${SSO_BASE_PATH}/authenticate`;
// EMAIL VERIFICATION
export const RequestEmailVerificationPath = (): string =>
  `${NOT_AUTHENTICATED_OPERATION_PATH}/request-email-verification`;
export const VerifyEmailPath = (): string =>
  `${NOT_AUTHENTICATED_OPERATION_PATH}/verify-email`;
// PHONE VERIFICATION BY SMS
export const RequestUserVerificationBySmsPath = (): string =>
  `${NOT_AUTHENTICATED_OPERATION_PATH}/request-phone-verification`;
export const VerifyPhonePath = (): string =>
  `${NOT_AUTHENTICATED_OPERATION_PATH}/verify-phone`;

export const RecoverPasswordPath = (
  userid: string,
  language: LanguageCode,
  emailConfiguration: BaseEmailConfiguration,
  customConfigs?: Partial<RecoverPasswordBaseParams>,
): string => {
  const { subjectResourcekey, bodyResourcekey } = emailConfiguration;
  if (!contextualConfig.webApplication) {
    throw new Error('webApp not configured in contextual config');
  }
  const { contextPath } = contextualConfig.webApplication;
  const { defaultResourceBundleName } = contextualConfig.application;
  const recoverPwLinkPath = Container.has(
    AUTHENTICATION_EMAIL_APPLICATION_BASE_URL,
  )
    ? `${Container.get(
        AUTHENTICATION_EMAIL_APPLICATION_BASE_URL,
      )}/recover-password/{0}`
    : `${getDefaultApiHostUrl()}${contextPath}/recover-password/{0}`;

  const bundle = Container.has(RECOVER_PASSWORD_BUNDLE_TOKEN)
    ? Container.get(RECOVER_PASSWORD_BUNDLE_TOKEN)
    : defaultResourceBundleName;

  const { salesOrgBrandID } = contextualConfig.application;

  return `${getDefaultApiHostUrl()}/api-v2/v1/trader/recover-password/password?${qs.stringify(
    {
      language,
      userId: userid,
      subjectResourceKey: subjectResourcekey,
      recoverPwLinkPath,
      emailMessageResourceKey: bodyResourcekey,
      bundle,
      salesOrgBrandID,
      ...customConfigs,
    },
  )}`;
};

export const RecoverUsernamePath = (
  email: string,
  language: LanguageCode,
  emailConfiguration: BaseEmailConfiguration,
): string => {
  const { subjectResourcekey, bodyResourcekey } = emailConfiguration;
  if (!contextualConfig.webApplication) {
    throw new Error('webApp not configured in contextual config');
  }
  const { defaultResourceBundleName } = contextualConfig.application;
  const { salesOrgBrandID } = contextualConfig.application;

  return `${getDefaultApiHostUrl()}/api-v2/v1/trader/recover-password/username?${qs.stringify(
    {
      language,
      email,
      subjectResourceKey: subjectResourcekey,
      emailMessageResourceKey: bodyResourcekey,
      bundle: defaultResourceBundleName,
      salesOrgBrandID,
    },
  )}`;
};

export const ValidateChangePasswordUuidPath = (): string =>
  `${getDefaultApiHostUrl()}/api-v2/v1/trader/recover-password/validate-changepw-uuid`;
export const ChangePasswordPath = (language: LanguageCode): string =>
  `${getDefaultApiHostUrl()}/api-v2/v1/trader/recover-password/changepw?language=${language}`;
