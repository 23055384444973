import find from 'lodash/find';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { availableSalutationsSelector } from '../stateSelector';

const useAvailableSalutations = () => {
  const availableSalutations = useSelector(availableSalutationsSelector);
  return availableSalutations;
};

export function useTranslatedSalutation(currentSalutayion: string) {
  const availableSalutations = useAvailableSalutations();
  return useMemo(() => {
    const slutationData = find(
      availableSalutations,
      s => s.id === currentSalutayion,
    );

    return slutationData?.name;
  }, [availableSalutations, currentSalutayion]);
}

export default useAvailableSalutations;
