import { I18nShape } from '@mmw/constants-i18n';
import I18N from '@mmw/constants-i18n-user';
import { useInputFieldConfigProps } from '@mmw/redux-store-fast-form/hooks/fields';
import { deepmerge } from '@shared-utils/object';
import map from 'lodash/map';
import React, { useMemo } from 'react';
import { O } from 'ts-toolbelt';

import FastFormSelect, { FastFormSelectProps } from './FastFormSelect';

type Props = {
  fieldPath: string;
  labelI18n?: I18nShape;
  hideLabel?: boolean;
  roles: { name: string; id: string }[];
} & O.Omit<FastFormSelectProps, 'placeholderI18n' | 'options'>;

const FastFormRoleSelect: React.FC<Props> = ({
  fieldPath,
  hideLabel,
  label,
  roles,
  ...props
}: Props) => {
  const options = useMemo(
    () =>
      map(roles, legalForm => ({
        label: legalForm.name,
        value: legalForm.id,
      })),
    [roles],
  );

  const inputFieldConfigProps = useInputFieldConfigProps(fieldPath);
  const otherProps = useMemo(
    () => deepmerge(inputFieldConfigProps, props),
    [inputFieldConfigProps, props],
  );

  return (
    <FastFormSelect
      fieldPath={fieldPath}
      label={hideLabel ? undefined : label}
      placeholderI18n={label}
      options={options}
      {...otherProps}
    />
  );
};

FastFormRoleSelect.defaultProps = {
  labelI18n: I18N.ROLE,
};

export default FastFormRoleSelect;
