import { SecurityScopeNames } from '@mmw/services-auth-api-authentication/types';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { useGetToken } from '@recaptcha/v3';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';

import { loginByUserAndPassStartAction } from '../actions';
import type { AuthenticationResponseWithUser } from '../types';

export class LoginForm {
  username = '';

  password = '';
}

export const LOGIN_FORM_INITIAL_VALUES = new LoginForm();

export const LOGIN_FORM_FIELD_PATHS = getFieldPaths(LOGIN_FORM_INITIAL_VALUES);

type OnLoginSuccessCb = F.Function<[AuthenticationResponseWithUser]>;

type UseLoginByUserAndPassCb = F.Function<[LoginForm, SecurityScopeNames[]?]>;

const useLoginByUserAndPass = (
  onLoginSuccess?: OnLoginSuccessCb,
  disableAuthRedirection?: boolean,
): UseLoginByUserAndPassCb => {
  const dispatch = useDispatch();
  const getToken = useGetToken();
  return useCallback(
    async (data: LoginForm, scopeNames?: SecurityScopeNames[]) => {
      const username = get(data, 'username', '');
      const password = get(data, 'password', '');
      try {
        const token = await getToken('login-by-user-and-password');

        if (username && password) {
          dispatch(
            loginByUserAndPassStartAction(
              username,
              password,
              token,
              onLoginSuccess,
              scopeNames,
              disableAuthRedirection,
            ),
          );
        }
      } catch (e) {
        if (username && password) {
          dispatch(
            loginByUserAndPassStartAction(
              username,
              password,
              undefined,
              onLoginSuccess,
              scopeNames,
              disableAuthRedirection,
            ),
          );
        }
      }
    },
    [getToken, dispatch, onLoginSuccess],
  );
};

export default useLoginByUserAndPass;
