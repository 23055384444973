import { Color } from '@ui-system/css';
import { nanoid } from 'nanoid';
import React from 'react';

export interface SkeletonProps {
  count?: number;
  height?: number | string;
  width?: number | string;
  color?: Color | string;
  highlightColor?: Color | string;
  children?: React.ReactElement | React.ReactElement[];
  isLoading?: boolean;
}

// export interface NativeSkeletonStatics {
//   Item: React.ReactNode;
// }

export const SKELETON_UUID = nanoid();

export type SkeletonType<AdditionalProps = Record<string, any>> = React.FC<
  Partial<SkeletonProps> & AdditionalProps
>;
